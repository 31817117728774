// React Hooks
import React, { useState, useContext, useEffect, useRef } from "react";
import { AuthContext } from "../../../../context/AuthProvider";

//Material Components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Hidden } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import DownloadIcon from "@mui/icons-material/Download";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Components
import ProjectCardAreaControl from "../../../../components/projects/ProjectCardAreaControl";
import Ocupation from "../../../../components/reports/Ocupation/Ocupation";
import Temperature from "../../../../components/highcharts/Temperature";
import Measurer from "../../../../components/highcharts/Measurer";

// Services
import { calc_device_fails, devices_fails } from "../../../../utils/helpers";

//Multilanguage
import { useTranslation } from "react-i18next";
import { MenuButton } from "./menuDevices/MenuButton";
import { CreateDevices } from "./menuDevices/EditDevices";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#3f9948",
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  graphic: {
    marginTop: 30,
  },
  image: {
    position: "relative",
    height: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  table: {
    minWidth: 700,
  },
  info: {
    marginBottom: 60,
  },
  tab: {
    marginTop: 60,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Header = ({ data, devices, project, refetch }) => {
  const [openReport, setOpenReport] = useState("ocupation");
  const [createDevices, setCreateDevice] = useState(false);
  const { t } = useTranslation();
  const childRef = useRef();
  const {
    serial,
    setSerial,
    area,
    roomId,
    setRoomId,
    setArea,
    setIdArea,
    setSuccessMessage,
    setErrorMessage,
    setactiveNextBack,
    loadingPdf,
    setLoadingPdf,
    permissionsRoleUser,
    setCalendarDatePicker,
    occupationAreaName,
    setOccupationAreaName,
    nameHeaderOcupation,
    setGlobalCalendarDay,
    setIdHeaderOcupation,
  } = useContext(AuthContext);
  // Context global

  // esto es data del Header
  const img = project?.img;
  // esta data es del getDevices

  const alias = devices?.alias;

  const listMacromeasureOption =
    data?.getMettersInProjets?.Metters?.length > 0 ||
    data?.getInfoMetersByProject?.length > 0;

  const [open, setOpen] = useState(false);
  const [openMeasurer, setOpenMeasurer] = useState(false);
  const [openTemperature, setOpenTemperature] = useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    const day_by_miliseconds = 86400000;
    // Hora actual
    const actual_hour = new Date().getHours();
    // Hora inicio
    const hotel_hour = Number(
      localStorage.getItem("startTime").substring(0, 2)
    );
    setIdHeaderOcupation(data?.getAllDevices?.id);
    if (openReport == "ocupation") {
      setCalendarDatePicker(
        actual_hour < hotel_hour
          ? new Date(new Date().getTime() - day_by_miliseconds)
          : new Date()
      );
      setArea(data?.getAllDevices?.alias);
      setIdArea(data?.getAllDevices?.id);
      localStorage.setItem("selectedAreaName", data?.getAllDevices?.name);
      setOccupationAreaName(localStorage.getItem("selectedAreaName"));
      setOpen(true);

      if (
        localStorage.getItem("startTime") === "0:00" ||
        localStorage.getItem("startTime") === "00:00"
      ) {
        setGlobalCalendarDay(new Date());
      } else if (localStorage.getItem("startTime") === "15:00") {
        if (actual_hour < 15) {
          setGlobalCalendarDay(
            new Date(new Date(new Date().getTime() - day_by_miliseconds))
          );
        } else if (actual_hour >= 15) {
          setGlobalCalendarDay(new Date());
        }
      }
    } else if (openReport === "temperature") {
      setOpenTemperature(true);
      setactiveNextBack(true);
      if (
        localStorage.getItem("startTime") === "0:00" ||
        localStorage.getItem("startTime") === "00:00"
      ) {
        setGlobalCalendarDay(new Date());
      } else if (localStorage.getItem("startTime") === "15:00") {
        if (actual_hour < 15) {
          setGlobalCalendarDay(
            new Date(new Date(new Date().getTime() - day_by_miliseconds))
          );
        } else if (actual_hour >= 15) {
          setGlobalCalendarDay(new Date());
        }
      }
    } else if (openReport === "measurer") {
      setOpenMeasurer(true);
    }
  };

  const handleOpenReports = (event) => {
    const value = event.target.value;
    setOpenReport(value);
  };

  const handleClose = () => {
    setOpen(false);
    setCalendarDatePicker(new Date());
  };

  const handleCloseMeasurer = () => {
    setOpenMeasurer(false);
  };

  const handleCloseTemperature = () => {
    setCalendarDatePicker(new Date());
    setOpenTemperature(false);
  };

  return (
    <div className="__devices_container_project_and_filter__">
      <div className="__devices_container_logo__">
        <img
          className="__devices_img__"
          src={img}
          alt="Logo"
          width="180"
          height="180"
        />
      </div>
      <div className="__devices_container_project_filters__">
        <div className="__devices_container_project__">
          <ProjectCardAreaControl data={project} />
        </div>
        <div className="__devices_container_filter__">
          <div className="__devices_container_name__">
            <span className="__devices_name__">{alias}</span>
          </div>
          <div className="__devices_container_report__">
            <div className="_devices_report">
              <span className="_report_select">
                <select
                  name="report"
                  id=""
                  className="_reprot_select_option"
                  onChange={
                    permissionsRoleUser?.permissionsRole?.AreaDispMan
                      ? (e) => handleOpenReports(e)
                      : false
                  }
                >
                  <option value="ocupation">
                    {t("VIEW_AREA.COMPONENT_REPORT_SELECT.OCUPATION")}
                  </option>
                  {/* {listMacromeasureOption && (
                    <option value="measurer">Medidores</option>
                  )} */}
                  <option value="temperature">
                    {t("VIEW_AREA.COMPONENT_REPORT_SELECT.TEMPERATURE")}
                  </option>
                </select>
              </span>
              <span className="_report_ver_container">
                <button
                  type="text"
                  className="_report_ver"
                  onClick={
                    permissionsRoleUser?.permissionsRole?.AreaRoaVer
                      ? () => {
                          handleClickOpen();
                        }
                      : false
                  }
                >
                  <i class="fas fa-eye"></i>
                </button>
              </span>
            </div>
          </div>
          {permissionsRoleUser?.permissionsRole?.AreaDispEdit ? (
            <MenuButton
              setCreateDevice={setCreateDevice}
              devices={devices}
              refetch={refetch}
            />
          ) : null}

          <div className="__devices_container_count_devices__">
            <span className="_count_container">
              <span>
                <i class="fas fa-desktop"></i>
              </span>{" "}
              <span className="_count_devices">
                {/* {calc_device_fails(totalDevices)}/<span className="_count_devices_fails">{devices_fails(totalDevices)}</span> */}
              </span>
            </span>
          </div>
        </div>
      </div>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {`${data?.getProjectByIdHeader?.name} - ${t(
                "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.HEADER_TITLE"
              )} ${occupationAreaName}`}
            </Typography>

            {permissionsRoleUser?.permissionsRole?.AreaRoaDes ? (
              <Hidden mdDown>
                <LoadingButton
                  //disabled={roomPdf.rooms.length == 0 || roomPdf == undefined}
                  onClick={() => childRef?.current?.occupationPdf()}
                  endIcon={<DownloadIcon />}
                  loading={loadingPdf}
                  loadingPosition="end"
                  color="inherit"
                >
                  {t(
                    "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.DOWNLOAD"
                  )}
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 100,
                    }}
                    open={loadingPdf}
                    onClick={loadingPdf}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </LoadingButton>
              </Hidden>
            ) : null}
          </Toolbar>
        </AppBar>
        <Ocupation
          project={data?.getProjectByIdHeader}
          RoomId={devices?.id}
          roomIdDatepicker={Number(devices?.id)}
          NewRoomId={data?.getAllDevices?.NewRoomId}
          typeGateway={devices?.Controller?.typeGateway}
          ref={childRef}
        />
      </Dialog>

      <Dialog
        fullScreen
        open={openTemperature}
        onClose={handleCloseTemperature}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseTemperature}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {`${data?.getProjectByIdHeader?.name} - ${t(
                "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_MODAL_TEMPERATURE.MODAL_HEADER_TITLE"
              )} ${occupationAreaName}`}
            </Typography>
          </Toolbar>
        </AppBar>
        <Temperature
          project={data?.getProjectByIdHeader}
          RoomId={devices?.id}
          NewRoomId={data?.getAllDevices?.NewRoomId}
          typeGateway={devices?.Controller?.typeGateway}
        />
      </Dialog>

      {/* <Dialog
        fullScreen
        open={openMeasurer}
        onClose={handleCloseMeasurer}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseMeasurer}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {data?.getProjectByIdHeader?.name}
            </Typography>
          </Toolbar>
        </AppBar>
        <Measurer project={data?.getProjectByIdHeader} RoomId={devices?.id} />
      </Dialog> */}
    </div>
  );
};
export default Header;
