import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { AuthContext } from "../../context/AuthProvider";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function MessageSuccess() {
  const { successMessage, setSuccessMessage } = useContext(AuthContext);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessMessage(!successMessage);
  };

  return (
    <Snackbar
      open={successMessage}
      autoHideDuration={2000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="success">
        Comunicación exitosa
      </Alert>
    </Snackbar>
  );
}

export default MessageSuccess;
