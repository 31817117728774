import React, { useContext } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { AuthContext } from "../../context/AuthProvider";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MessageWarning = (props) => {
  const { textMessage } = props;
  const { warningMessage, setWarningMessage } = useContext(AuthContext);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setWarningMessage(!warningMessage);
  };
  return (
    <Snackbar
      open={warningMessage}
      autoHideDuration={2000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="warning">
        {textMessage ? textMessage : "Conexión agotada"}
      </Alert>
    </Snackbar>
  );
};

export default MessageWarning;
