import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../context/AuthProvider";
import { useQuery } from "@apollo/client";
import { GET_HISTORY } from "../../../controllers/measurersController";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import LoadingBar from "../../loading/LoadingBar";
import { TableRow, TableCell } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Select from "../../macrometer/selectMonth/selectMonth";

//Function
import {
  sum_of_the_kwh,
  date_of_the_kwh,
  round_digits,
  convert_time_measurergeneral,
  calc_date_range,
  date_last,
  define_time_zone,
  defice_toolTip_date,
  dateRangeButton,
  calculatedMinParameter,
  calculatedMaxParameter,
  roundTotalBy,
  sumTotalBy,
  timeConvertMacromedidor,
} from "../../../utils/helpers";
import { Dataset } from "@mui/icons-material";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    fontWeight: "bold",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const GraphConsumptionDetailPerYear = ({ projectID, timeZone }) => {
  const { dateReport, serial, setSeeGraphic } = useContext(AuthContext);
  useEffect(() => {
    setSeeGraphic(false);
  }, []);
  const [parameterMax, setParameterMax] = useState(
    calculatedMaxParameter(dateReport, timeZone)
  );
  const [parameterMin, setParameterMin] = useState(
    calculatedMinParameter(dateReport, timeZone)
  );
  const [date_range, setDateRange] = useState([]);
  const [dayActual, setDayActual] = useState(new Date().getTime());
  const [dateMax, setDateMax] = useState();
  const [dateMin, setDateMin] = useState();
  const [value, setValue] = useState();
  const [datas, setDatas] = useState([]);
  const [selectedOption, setSelectedOption] = React.useState("");
  const [year, setYear] = useState(timeConvertMacromedidor().year);
  const currentYear = new Date().getFullYear();
  const listedYears = [];
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const { data, loading } = useQuery(GET_HISTORY, {
    variables: {
      serial: serial,
      year: parseInt(year),
      time_zone: timeZone,
    },
    fetchPolicy: "no-cache",
  });
  const [x, setX] = useState([]);

  if (loading || !data) {
    return <></> /* <LoadingBar / */;
  }

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setYear(event.target.value);
  };

  for (let i = 2022; i <= currentYear; i++) {
    listedYears?.push({
      label: String(i),
      value: Number(i),
    });
  }

  return (
    <div className="__details_measurer_consumption_graphic__">
      <div className="calendar_content">
        <Select
          options={listedYears?.reverse()}
          value={selectedOption}
          onChange={handleSelectChange}
        />
      </div>
      {data.getHistory.length === 0 ? (
        <div className="date_vacio_report_measurer">
          <span>
            <i class="fas fa-exclamation-triangle"></i> No hay datos
          </span>
        </div>
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: "column",
            },
            colors: ["#04b431"],

            title: {
              align: "left",
              text: `Consumo total de ${
                data.getHistory.res
                  ? data.getHistory.currentConsumption.toFixed(2)
                  : datas
              } kWh `,
            },
            subtitle: {
              align: "left",
              text: "" /* "Click the columns to view details. " */,
            },
            accessibility: {
              announceNewData: {
                enabled: true,
              },
            },
            xAxis: {
              type: "category",
            },
            yAxis: {
              title: {
                text: "",
              },
            },
            legend: {
              enabled: false,
            },
            plotOptions: {
              series: {
                borderWidth: 0,
                dataLabels: {
                  enabled: true,
                  format: "{point.y:.1f}",
                },
              },
            },

            tooltip: {
              headerFormat:
                '<span style="font-size:11px">{series.name}</span><br>',
              pointFormat:
                '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> kWh<br/>',
            },

            series: [
              {
                name: "Mes",
                colorByPoint: true,
                data: data.getHistory.res,
              },
            ],
          }}
        />
      )}
    </div>
  );
};

export default GraphConsumptionDetailPerYear;
