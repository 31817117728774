import gql from 'graphql-tag';

export const GET_INFO_MEASURER_BY_PROJECT = gql`
  query ($ProjectId: Int!) {
    getProjectByIdHeader(ProjectId: $ProjectId)
    getInfoMetersByProject(ProjectId: $ProjectId)
    getMettersInProjets(ProjectId: $ProjectId)
  }
`;

export const GET_PHASES_MEASURER_REPORT = gql`
  query ($ProjectId: Int!, $id_parent: Float!, $start_time: Date!, $end_time: Date!, $date_range: [Date], $time_zone: String) {
    getPhasesMeasureReport(ProjectId: $ProjectId, id_parent: $id_parent, start_time: $start_time, end_time: $end_time, date_range: $date_range, time_zone: $time_zone)
  }
`;

export const GET_CLAMPS_MEASURE = gql`
  query ($deviceId: String!, $startTime: Float, $endTime: Float, $timeZone: String) {
    getClampsMeasure(deviceId: $deviceId, startTime: $startTime, endTime: $endTime, time_zone: $timeZone)
  }
`;

export const GET_DASHBOARD_MEASURER_REPORT = gql`
  query getDataDashboard {
    getDataDashboard(serial: "004000004694")
  }
`;

export const GET_HISTORY = gql`
  query ($serial: String!, $start_time: Date, $end_time: Date, $time_zone: Float, $month: Int, $year: Int) {
    getHistory(serial: $serial, start_time: $start_time, end_time: $end_time, time_zone: $time_zone, month: $month, year: $year)
  }
`;

export const GET_TWELVE_HOUR_VOLTAGE = gql`
  query ($serial: String!, $start_time: Date!, $end_time: Date!, $time_zone: Float) {
    getTwelveHourVoltage(serial: $serial, start_time: $start_time, end_time: $end_time, time_zone: $time_zone)
  }
`;
export const GET_TWELVE_HOUR_AMPS = gql`
  query ($serial: String!, $start_time: Date!, $end_time: Date!, $time_zone: Float) {
    getTwelveHourAmperage(serial: $serial, start_time: $start_time, end_time: $end_time, time_zone: $time_zone)
  }
`;
export const GET_TWELVE_HOUR_WATTS = gql`
  query ($serial: String!, $start_time: Date!, $end_time: Date!, $time_zone: Float) {
    getTwelveHourWatting(serial: $serial, start_time: $start_time, end_time: $end_time, time_zone: $time_zone)
  }
`;

export const GET_METTER_AND_METTER_HISTORIES = gql`
  query ($serial: String!) {
    getMetterAndMetterHistories(serial: $serial)
  }
`;

export const GET_MACROMETER_IN_PROJECT = gql`
query ($ProjectId: Int!) {
    getMettersInProjets(ProjectId: $ProjectId)
  }
`

export const GET_METTERS_HISTORY = gql`
 query getMeterHistory($serial: String!) {
  getMeterHistory(serial: $serial) {
    VFA
    VFB
    VFC
    CFA
    CFB
    CFC
    FPFA
    FPFB
    FPFC
    PAFA
    PAFB
    PAFC
  }
}
`;

export const GET_METER_HISTORY_VARIABLES = gql`
 query GetMeterHistoryVariables($serial: [String]!, $location: String) {
  getMeterHistoryVariables(serial: $serial, location: $location) {
    SerialMedidor
    variable {
      FHz
      NC
      Ah
      TExKVarh
      TExKwh
      TImKVarh
      TImKwh
      TKWh
      VFBFC
      VFAFB
      VFCFA
      TSE
      EI
      TFT
      TSVa
      TSVar
      TVah
    }
  }
}
`;

export const GET_HISTORY_TWO = gql`
  query Query($serial: String!, $starTime: String, $endTime: String, $year: Int, $location:String) {
    getConsumptionHistory(serial: $serial, starTime: $starTime, endTime: $endTime, year: $year, location:$location)
  }
`;

export const GET_TWELVE_HOUR_VOLTAGE_TWO = gql`
  query Query($serial: String!, $starTime: Int!) {
    getTwelveHourVoltage(serial: $serial, starTime: $starTime)
  }
`;

export const GET_TWELVE_HOUR_CURRENT = gql`
  query Query($serial: String!, $starTime: Int!) {
    getTwelveHourCurrent(serial: $serial, starTime: $starTime)
  }
`;

export const GET_TWELVE_HOUR_WATTS_TWO = gql`
  query Query($serial: String!, $starTime: Int!) {
    getTwelveHourWatt(serial: $serial, starTime: $starTime)
  }
`;

export const GET_TWELVE_HOUR_POWER_FACTOR = gql`
  query Query($serial: String!, $starTime: Int!) {
    getTwelveHourPowerFactor(serial: $serial, starTime: $starTime)
  }
`;

export const GET_NEW_INSTALLED_METTERS = gql`
  query Query($projectId: Int!) {
    getNewMeters(projectId: $projectId)
  }
`;

export const UPDATE_NEW_MACROMETER = gql`
  mutation UpdateMacroMeter($meters: JSON) {
    updateMacroMeter(meters: $meters)
  }
`;

export const GET_FREQUENCY_TEST = gql`
  query Query($serial: String!, $starTime: Int!) {
    getFrequencyGraphs(serial: $serial, starTime: $starTime)
  }
`;

export const GET_ARMONIC_CURRENT_TEST = gql`
  query Query($serial: String!, $starTime: Int!) {
    getHarmonicCurrentGraphs(serial: $serial, starTime: $starTime)
  }
`;

export const GET_ARMONIC_VOLTAGE_TEST = gql`
  query Query($serial: String!, $starTime: Int!) {
    getHarmonicVoltageGraphs(serial: $serial, starTime: $starTime)
  }
`;
