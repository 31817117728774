import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
//Multilanguage
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../context/AuthProvider";
import { EditDevices } from "./EditDevices";

export const MenuButton = ({ devices, refetch }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [createDevices, setCreateDevices] = useState(false);
  const open = Boolean(anchorEl);
  const { permissionsRoleUser } = useContext(AuthContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlerMenu = () => {
    setCreateDevices(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {permissionsRoleUser?.permissionsRole?.AreaDisp &&
      permissionsRoleUser?.permissionsRole?.AreaDispMan ? (
        <div className="add_device_button">
          <Button
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            sx={{
              border: "1px solid",
              borderColor: "white",
              boxShadow: "1px 1px 4px 4px rgba(146, 158, 158, 0.3)",
            }}
            onClick={handleClick}
          >
            <MoreVertIcon color="action" />
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handlerMenu}>
              {t("VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.EDIT_DEVICE")}
            </MenuItem>
          </Menu>
        </div>
      ) : null}
      {permissionsRoleUser?.permissionsRole?.AreaDispMan ? (
        <EditDevices
          open={createDevices}
          setCreateDevices={setCreateDevices}
          devices={devices}
          refetch={refetch}
        />
      ) : null}
    </>
  );
};
