import React, { useContext } from "react";

//Global Context
import { AuthContext } from "../../context/AuthProvider";

//Helper Functions
import { handle_status } from "./helper";

//Language
import { useTranslation } from "react-i18next";

const AreaSelector = (props) => {
  const { changeRoomId } = props;
  const { idHeaderOcupation, setNameHeaderOcupation, setOccupationAreaName } =
    useContext(AuthContext);
  const { t } = useTranslation();

  const filteredSelectedArea = JSON.parse(localStorage.getItem("areas"))?.filter(
    (i) => i.id !== Number(idHeaderOcupation)
  );

  return (
    <div className="area_selector_container">
      <select
        className="selector"
        onChange={(i) => changeRoomId(i.target.value)}
      >
        {JSON.parse(localStorage.getItem("areas"))
          ?.filter((i) => i?.id == idHeaderOcupation)
          .map((i, key) => (
            <option
              className="option"
              selected
              disabled
              value={i?.id}
              key={key}
            >
              {i.name} - {t(`${handle_status(i?.status)}`)}
              {localStorage.setItem("selectedAreaName", i?.realname)}
              {setNameHeaderOcupation(i?.realname)}
              {setOccupationAreaName(localStorage.getItem("selectedAreaName"))}
            </option>
          ))}
        {filteredSelectedArea.map((i, key) => (
          <option value={i?.id} key={key}>
            {i?.name} - {t(`${handle_status(i?.status)}`)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AreaSelector;
