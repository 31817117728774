// Lib
import React, { useState, useEffect, useRef, useContext } from "react";
import { useQuery } from "@apollo/client";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useParams } from "react-router";
import { AuthContext } from "../../../context/AuthProvider";
import html2canvas from "html2canvas";
import { useHistory } from "react-router-dom";

// Services
import { GET_ALL_DEVICES } from "../../../controllers/devicesController";

// MAterial Styles
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";

//Material components
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import MessageError from "../../../components/message/MessageError";
import MessageSuccess from "../../../components/message/MessageSuccess";
import MessageWarning from "../../../components/message/MessageWarning";

import Header from "./components/Header";
import TemperatureContainer from "./components/Temperature/Temperature";

import {
  extrationAire,
  useDevices,
  useExtrationDevices,
} from "./Hooks/useDevices";
import Swicth from "./components/Swicth/Swicth";
import NewDoorSensor from "./components/sensors/NewDoorSensor";
import NewHSC from "./components/plugins/HSC";
import useSocket from "./Hooks/useWebSocket";
import NewMotionSensor from "./components/sensors/NewMotionSensor";
import Thermostat from "./components/Temperature/Thermostat";
import Vrf from "./components/Temperature/Vrf/Vrf";
import CurtainSwitch from "../../../components/devices/CurtainSwitch/CurtainSwitch";
import { ExtractTypeGateway } from "../../../utils/helpers";
import {
  spliceDevicesData,
  renderNewDevices,
  getSocket,
  IsThereVRF,
  SocketVRF,
} from "./helperDevices";
import Loading from "../../../components/loading/Loading";
import Construction from "../../../components/loading/Construction";

//multilanguage
import { useTranslation } from "react-i18next";
import { Lock } from "./components/doorlook/Lock";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#3f9948",
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  graphic: {
    marginTop: 30,
  },
  image: {
    position: "relative",
    height: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  table: {
    minWidth: 700,
  },
  info: {
    marginBottom: 60,
  },
  tab: {
    marginTop: 60,
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewDevices = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, loading, error, refetch } = useQuery(GET_ALL_DEVICES, {
    variables: {
      RoomId: parseInt(id, 10),
      ProjectId: parseInt(localStorage.getItem("id"), 10),
      typeGateway: ExtractTypeGateway(),
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const {
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage,
    permissionsRoleUser,
  } = useContext(AuthContext);

  const areaName = data?.getAllDevices?.name;
  const devices = data?.getAllDevices;
  const dataProject = data?.getProjectByIdHeader;
  const { switches } = useExtrationDevices(data?.getAllDevices?.Device);
  const { airs } = useExtrationDevices(data?.getAllDevices?.Device);
  const { sensorsDoor } = useExtrationDevices(data?.getAllDevices?.Device);
  const { sensorsMotion } = useExtrationDevices(data?.getAllDevices?.Device);
  const { plugins } = useExtrationDevices(data?.getAllDevices?.Device);
  const { curtainSwitch } = useExtrationDevices(data?.getAllDevices?.Device);
  const dataDevices = switches
    ?.concat(sensorsDoor)
    ?.concat(sensorsMotion)
    ?.concat(curtainSwitch);
  const { vrf } = useExtrationDevices(data?.getAllDevices.Device);
  const { lock } = useExtrationDevices(data?.getAllDevices?.Device);
  const allVrf = IsThereVRF(data?.getAllDevices.Device);
  const serial = devices?.Controller?.serial;
  const controllerStatus = devices?.Controller?.online;
  const socket = getSocket();

  // WebSocket
  const {
    REACT_APP_SOCKET_STAGING_NEWCONTROLLER,
    REACT_APP_SOCKET_PRODUCTION_NEWCONTROLLER,
    REACT_APP_SOCKET_DEVELOP_NEWCONTROLLER,
  } = process.env;

  const url = "ws://10.0.5.101:8080";
  const { client, sendMessage, dataSocket } = useSocket(
    REACT_APP_SOCKET_STAGING_NEWCONTROLLER,
    serial
  );

  if (!data || loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (error) {
    return <Construction />;
  }

  return (
    <>
      <div className="_devices_container">
        {data && data?.getAllDevices?.Controller?.online ? (
          <></>
        ) : (
          <div className="_label_offline">
            <span className="_label_offline_container">
              <span className="_icon_label_offline">
                <i className="fas fa-exclamation-triangle"></i>
              </span>
              <span className="_title_label_offline">
                {t("VIEW_AREA.COMPONENT_HEADER_STATUS_NOTIFICATION")}
              </span>
            </span>
          </div>
        )}
        <Header
          data={data}
          devices={devices}
          project={dataProject}
          refetch={refetch}
        />
        <div className="_container_devices_all">
          {permissionsRoleUser?.permissionsRole?.AreaDispVer &&
            permissionsRoleUser?.permissionsRole?.AreaDisp &&
            plugins?.map((i) => (
              <NewHSC
                controllerStatus={controllerStatus}
                dataSocket={dataSocket}
                hsc={i}
                project={dataProject}
                serial={serial}
                sendMessage={sendMessage}
              />
            ))}
          {permissionsRoleUser?.permissionsRole?.AreaDisp &&
            permissionsRoleUser?.permissionsRole?.AreaDispVer &&
            airs?.map((i, key) => (
              <TemperatureContainer
                areaName={areaName}
                allAirs={i}
                controllerStatus={controllerStatus}
                dataSocket={dataSocket}
                key={key}
                refetch={refetch}
                sendMessage={sendMessage}
                serial={serial}
              />
            ))}

          {permissionsRoleUser?.permissionsRole?.AreaDispVer &&
          permissionsRoleUser?.permissionsRole?.AreaDisp
            ? vrf?.map((i, key) => (
                <Vrf
                  dataSocket={socket}
                  key={key}
                  sendMessage={socket}
                  serial={serial}
                  vrf={i}
                />
              ))
            : null}

          {permissionsRoleUser?.permissionsRole?.AreaDispVer &&
          permissionsRoleUser?.permissionsRole?.AreaDisp
            ? lock?.map((i, key) => (
                <Lock
                  key={key}
                  lock={i}
                  dataSocket={dataSocket}
                  sendMessage={sendMessage}
                  serial={serial}
                  controllerStatus={controllerStatus}
                />
              ))
            : null}

          {renderNewDevices(spliceDevicesData(dataDevices, 2), {
            ...{
              serial,
              sendMessage,
              dataSocket,
              controllerStatus,
              permissionsRoleUser,
            },
          })}
        </div>
        <MessageSuccess />
        <MessageError />
        <MessageWarning />
      </div>
    </>
  );
};

export default NewDevices;
