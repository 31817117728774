export const handle_status = (status) => {
  if (!status) return "VIEW_DATE_PICKER.STATUS_STATELESS";
  const values = {
    0: "VIEW_DATE_PICKER.STATUS_FREE",
    25: "VIEW_DATE_PICKER.STATUS_DIRTY",
    50: "VIEW_DATE_PICKER.STATUS_SOLD",
    75: "VIEW_DATE_PICKER.STATUS_CLEANING",
    100: "VIEW_DATE_PICKER.STATUS_BLOCKED",
    125: "VIEW_DATE_PICKER.STATUS_BOOKED",
    Libre: "VIEW_DATE_PICKER.STATUS_FREE",
    Ocupado: "VIEW_DATE_PICKER.STATUS_BUSY",
  };
  return values[status] ?? "VIEW_DATE_PICKER.STATUS_STATELESS";
};
