import { useContext, useState, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { useQuery } from "@apollo/client";
import { GET_HISTORY_TWO } from "../../../controllers/measurersController";
import { secondClient } from "../../..";
import { timeConvertMacromedidor } from "../../../utils/helpers";
import { AuthContext } from "../../../context/AuthProvider";
import Select from "../../macrometer/selectMonth/selectMonth";

//multilanguage
import { useTranslation } from "react-i18next";

export const NewGraphConsumptionDetailYear = ({ serial, city }) => {
  const { t } = useTranslation();
  const {
    setSeeGraphic,
    rangeDatePicker,
    setRangeDatePicker,
    setRangeDatePickerToday,
  } = useContext(AuthContext);
  const [selectedOption, setSelectedOption] = useState("");
  const [year, setYear] = useState(timeConvertMacromedidor().year);
  const listedYears = [];
  const currentYear = new Date().getFullYear();
  const [datas, setDatas] = useState(0);
  const codigosCiudades = {
    Bogotá: "BG",
    Barranquilla: "BQ",
    default: "BQ",
  };
  const { data } = useQuery(GET_HISTORY_TWO, {
    client: secondClient,
    variables: {
      serial: serial,
      year: parseInt(year),
      location: codigosCiudades[city] || codigosCiudades.default,
    },
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    setSeeGraphic(false);
    setRangeDatePicker([
      {
        ...rangeDatePicker.selection,
        startDate: new Date(new Date()?.getTime() - 2589000000),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setRangeDatePickerToday([
      {
        ...rangeDatePicker.selection,
        startDate: new Date(new Date()?.getTime() - 2589000000),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  }, []);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setYear(event.target.value);
  };

  for (let i = 2022; i <= currentYear; i++) {
    listedYears?.push({
      label: String(i),
      value: Number(i),
    });
  }

  return (
    <div className="__details_measurer_consumption_graphic__">
      <div className="calendar_content">
        <Select
          options={listedYears?.reverse()}
          value={selectedOption}
          onChange={handleSelectChange}
        />
      </div>
      {data?.getConsumptionHistory.length === 0 ? (
        <></>
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              zooming: {
                mouseWheel: false,
              },
              type: "column",
            },
            colors: ["#04b431"],

            title: {
              align: "left",
              text: `${t(
                "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_TABS.CONSUMPTION_GRAPH_TITLE"
              )} ${
                data?.getConsumptionHistory?.currentConsumption.activeEnergy
                  ? data?.getConsumptionHistory?.currentConsumption.activeEnergy
                  : datas
              } kWh `,
            },

            subtitle: {
              align: "left",
              text: "" /* "Click the columns to view details. " */,
              align: "left",
              text: "" /* "Click the columns to view details. " */,
            },
            accessibility: {
              announceNewData: {
                enabled: true,
              },
            },
            xAxis: {
              type: "category",
            },
            yAxis: {
              title: {
                text: "",
              },
            },
            legend: {
              enabled: false,
            },
            plotOptions: {
              column: {
                grouping: false,
                shadow: false,
                borderRadius: 4,
                borderWidth: 2,
                borderColor: "black",
              },
              series: {
                dataLabels: {
                  enabled: true,
                  format: "{point.y:.1f}",
                },
              },
            },

            tooltip: {
              headerFormat:
                '<span style="font-size:11px">{series.name}</span><br>',
              pointFormat:
                '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> kWh<br/>',
              headerFormat:
                '<span style="font-size:11px">{series.name}</span><br>',
              pointFormat:
                '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> kWh<br/>',
            },
            series: data?.getConsumptionHistory?.series,
            credits: {
              enabled: false,
            },
          }}
        />
      )}
    </div>
  );
};
