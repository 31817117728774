import React, { useState, useContext } from "react";

//Material UI components
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@mui/material/Select";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

//Material UI components & Styles
import { createTheme, ThemeProvider } from "@mui/material/styles";

//multilanguage
import { useTranslation } from "react-i18next";
import { DialogActions } from "@mui/material";
import { useMutation } from "@apollo/client";
import { UPDATE_DEVICES } from "../../../../../controllers/roomControllers";
import { AuthContext } from "../../../../../context/AuthProvider";

export const EditDevices = ({ open, setCreateDevices, devices, refetch }) => {
  const typeGateway = localStorage.getItem("typeGateway");
  const [updateDevices] = useMutation(UPDATE_DEVICES);
  const { t } = useTranslation();
  const customGreen = "#acf6ac";
  const theme = createTheme({
    palette: {
      success: {
        main: customGreen,
      },
    },
  });
  const { permissionsRoleUser } = useContext(AuthContext);

  const allDevices = devices?.Device;
  const handleClose = () => {
    setCreateDevices(false);
  };

  const option = [
    { name: "Si", value: true },
    { name: "No", value: false },
  ];
  const [dataEdit, setDataEdit] = useState({
    id: "",
    alias: "",
    name: "",
    typeGateway: typeGateway,
    setpoint: "",
    visible: "",
    report_energy: "",
    report_occupation: "",
    report_temp: "",
    watts: "",
    watts_min: "",
    watts_off: "",
  });
  const handlerPickDevice = (event) => {
    const id = event.target.value;
    const device = allDevices?.find((device) => device?.id === id);

    setDataEdit({
      id: device?.id || "",
      typeGateway: typeGateway,
      alias: device?.alias || "",
      name: device?.name || "",
      setpoint: device?.setpoint_temp || "",
      visible: device?.visible || "",
      report_energy: device?.report_energy || false,
      report_occupation: device?.report_occupation || false,
      report_temp: device?.report_temp || false,
      watts: device?.watts || "",
      watts_min: device?.watts_min || "",
      watts_off: device?.watts_off || "",
    });
  };
  const handlerData = (event) => {
    const { name, value } = event.target;

    setDataEdit((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlerUpdateDevices = () => {
    if (dataEdit.id) {
      try {
        updateDevices({
          variables: {
            updateDeviceId: dataEdit.id,
            typeGateway: dataEdit.typeGateway,
            name: dataEdit.name,
            alias: dataEdit.alias,
            visible: dataEdit.visible,
            reportEnergy: dataEdit.report_energy,
            reportOccupation: dataEdit.report_occupation,
            report_temp: dataEdit.report_temp,
            watts: dataEdit.watts,
            setpointTemp: dataEdit.setpoint,
            watts_min: dataEdit.watts_min,
            watts_off: dataEdit.watts_off,
          },
        });
        setTimeout(() => {
          refetch();
          handleClose();
        }, 2000);
      } catch (err) {
        console.error("Excepción:", err);
      }
    }
  };
  const dataDevice = () => {
    if (dataEdit.name === "Aire") {
      return 0;
    }
    return 1;
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            display: "flex",
            borderRadius: "8px",
            width: "35%",
            height: "auto",
            [theme.breakpoints.down("md")]: {
              width: "45%",
            },
            [theme.breakpoints.down("sm")]: {
              width: "90%",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#2EB142",
            color: "#FFFFFF",
            lineHeight: "2.6",
            padding: "5px 5px 5px 40px",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "auto",
              fontWeight: "bold",
            }}
          >
            {t("VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.TITLE")}
          </span>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: "#ffff" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "25px",
            padding: "20px 24px",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              width: "100%",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "18px",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                padding: "10px 14px",
              }}
            >
              <FormControl fullWidth size="small">
                <InputLabel
                  id="demo-simple-select-label"
                  // color={}
                >
                  {t("VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.LABEL")}
                </InputLabel>
                <Select
                  sx={{ borderRadius: "8px" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="id"
                  // value={areaId}
                  label={t(
                    "VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.LABEL"
                  )}
                  onChange={handlerPickDevice}
                >
                  {allDevices?.map((value) => (
                    <MenuItem key={value?.id} value={value?.id}>
                      {value?.serial}
                      {value?.name !== value?.serial ? ` ${value?.alias}` : ""}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                id="outlined-basic"
                label={t("VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.NAME")}
                variant="outlined"
                size="small"
                InputProps={{
                  sx: { borderRadius: "8px", width: "100%" },
                }}
                name="name"
                disabled={!dataEdit?.id}
                onChange={handlerData}
                value={dataEdit.name}
                required={true}
                //   color={areaName ? "primary" : "error"}
              />
              <TextField
                id="outlined-basic"
                label={t(
                  "VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.ALIAS"
                )}
                variant="outlined"
                disabled={!dataEdit?.id}
                name="alias"
                size="small"
                InputProps={{
                  sx: { borderRadius: "8px", width: "100%" },
                }}
                onChange={handlerData}
                value={dataEdit.alias}
              />
              {dataDevice() === 0 ? (
                <TextField
                  id="outlined-basic"
                  label={t(
                    "VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.SET_POINT"
                  )}
                  variant="outlined"
                  size="small"
                  name="setpoint"
                  disabled={!dataEdit?.id}
                  InputProps={{
                    sx: { borderRadius: "8px", width: "100%" },
                  }}
                  onChange={handlerData}
                  value={dataEdit.setpoint}
                />
              ) : null}

              <FormControl fullWidth size="small">
                <InputLabel>
                  {t("VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.VISIBLE")}
                </InputLabel>
                <Select
                  sx={{ borderRadius: "8px" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="visible"
                  disabled={!dataEdit?.id}
                  value={dataEdit.visible}
                  label={t(
                    "VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.VISIBLE"
                  )}
                  onChange={handlerData}
                >
                  {option?.map((value, key) => (
                    <MenuItem key={key} value={value?.value}>
                      {value?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {dataDevice() === 0 ? <FormControl fullWidth size="small">
                <InputLabel>
                  {t(
                    "VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.REPORT_ENERGY"
                  )}
                </InputLabel>
                <Select
                  sx={{ borderRadius: "8px" }}
                  name="report_energy"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disabled={!dataEdit?.id}
                  value={dataEdit.report_energy}
                  label={t(
                    "VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.REPORT_ENERGY"
                  )}
                  onChange={handlerData}
                >
                  {option?.map((value, key) => (
                    <MenuItem key={key} value={value?.value}>
                      {value?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>:null}
              
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                padding: "10px 14px",
              }}
            >
              <FormControl fullWidth size="small">
                <InputLabel>
                  {t(
                    "VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.REPORT_OCCUPATION"
                  )}
                </InputLabel>
                <Select
                  sx={{ borderRadius: "8px" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disabled={!dataEdit?.id}
                  value={dataEdit.report_occupation}
                  name="report_occupation"
                  label={t(
                    "VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.REPORT_OCCUPATION"
                  )}
                  onChange={handlerData}
                >
                  {option?.map((value, key) => (
                    <MenuItem key={key} value={value?.value}>
                      {value?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {dataDevice() === 0 ? (
                <FormControl fullWidth size="small">
                  <InputLabel>
                    {t(
                      "VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.REPORT_TEMPERATURE"
                    )}
                  </InputLabel>
                  <Select
                    sx={{ borderRadius: "8px" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    disabled={!dataEdit?.id}
                    name="report_temperature"
                    value={dataEdit.report_temp}
                    label={t(
                      "VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.REPORT_TEMPERATURE"
                    )}
                    onChange={handlerData}
                  >
                    {option?.map((value, key) => (
                      <MenuItem key={key} value={value?.value}>
                        {value?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}

              <TextField
                id="outlined-basic"
                label={t(
                  "VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.WATTS"
                )}
                variant="outlined"
                size="small"
                name="watts"
                InputProps={{
                  sx: { borderRadius: "8px", width: "100%" },
                }}
                disabled={!dataEdit?.id}
                onChange={handlerData}
                value={dataEdit.watts}
                //   color={areaName ? "primary" : "error"}
              />
              <TextField
                id="outlined-basic"
                label={t(
                  "VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.WATTS_MIN"
                )}
                variant="outlined"
                size="small"
                name="watts_min"
                InputProps={{
                  sx: { borderRadius: "8px", width: "100%" },
                }}
                disabled={!dataEdit?.id}
                onChange={handlerData}
                value={dataEdit.watts_min}
                //   color={areaName ? "primary" : "error"}
              />
              <TextField
                id="outlined-basic"
                label={t(
                  "VIEW_AREA.COMPONENT_DEVICE_ACTIONS_MENU_BUTTON.WATTS_OFF"
                )}
                variant="outlined"
                size="small"
                name="watts_off"
                InputProps={{
                  sx: { borderRadius: "8px", width: "100%" },
                }}
                disabled={!dataEdit?.id}
                onChange={handlerData}
                value={dataEdit.watts_off}
                //   color={areaName ? "primary" : "error"}
              />
            </div>
          </div>
        </DialogContent>
        {permissionsRoleUser?.permissionsRole?.AreaDispMan ? (
          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "-20px",
              marginBottom: "10px",
            }}
          >
            <ThemeProvider theme={theme}>
              <Button
                sx={{
                  display: "flex",
                  width: "90%",
                  padding: "7px",
                  color: "#000",
                  borderRadius: "8px",
                  lineHeight: "18px",
                }}
                variant="contained"
                color="success"
                onClick={() => handlerUpdateDevices()}
                disabled={dataEdit.id ? false : true}
              >
                {t("VIEW_AREAS.COMPONENT_EDIT_ROOM_MODAL.ACCEPT_BUTTON")}
              </Button>
            </ThemeProvider>
          </DialogActions>
        ) : null}
      </Dialog>
    </>
  );
};
