import {
  useContext,
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { useQuery } from "@apollo/client";
import { GET_HISTORY_TWO } from "../../../controllers/measurersController";
import { secondClient } from "../../..";
import DatesMacrometer from "../../../components/macrometer/datesMacrometer";
import { AuthContext } from "../../../context/AuthProvider";
import { timeConvertMacromedidor } from "../../../utils/helpers";
import DateMUI from "../../dateMUI/DateMUI";
import { Dates } from "../MeasurerDetails/helperDate";
import html2canvas from "html2canvas";

//multilanguage
import { useTranslation } from "react-i18next";

export const NewGraphConsumptionDetail = (props) => {
  const { serial, city } = props;

  const codigosCiudades = {
    Bogotá: "BG",
    Barranquilla: "BQ",
    default: "BQ",
  };
  Object.freeze(codigosCiudades);
  const { t } = useTranslation();
  const {
    dateReport,
    dateMacrometers,
    dateMacrometersTwo,
    dateMUI,
    setGraphic,
    setLoadingPdf,
    setSeeGraphic,
    rangeDatePicker,
    setRangeDatePicker,
    setRangeDatePickerToday,
  } = useContext(AuthContext);
  const [datas, setDatas] = useState(0);
  // const printRef = useRef();
  const { data } = useQuery(GET_HISTORY_TWO, {
    client: secondClient,
    variables: {
      serial: serial,
      location: codigosCiudades[city] || codigosCiudades.default,
      starTime: String(Dates(dateMUI).start),
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    setSeeGraphic(true);
    setRangeDatePicker([
      {
        ...rangeDatePicker.selection,
        startDate: new Date(new Date()?.getTime() - 2589000000),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setRangeDatePickerToday([
      {
        ...rangeDatePicker.selection,
        startDate: new Date(new Date()?.getTime() - 2589000000),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  }, []);
  return (
    <div className="__details_measurer_consumption_graphic__">
      <div className="calendar_content">
        <DateMUI />
      </div>

      {data?.getConsumptionHistory?.series?.length === 0 ? (
        <></>
      ) : (
        // <div ref={tabValue == "1" ? printRef : ""}>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              zooming: {
                mouseWheel: false,
              },
              type: "column",
            },
            colors: ["#04b431"],

            title: {
              align: "left",
              text: `${t(
                "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_TABS.CONSUMPTION_GRAPH_TITLE"
              )} ${
                data?.getConsumptionHistory?.currentConsumption?.activeEnergy
                  ? data?.getConsumptionHistory?.currentConsumption
                      ?.activeEnergy
                  : datas
              } kWh `,
            },

            subtitle: {
              align: "right",

              style: {
                color: "#000",
                fontSize: "16px",
              },
              y: 40,
            },
            accessibility: {
              announceNewData: {
                enabled: true,
              },
            },
            xAxis: {
              type: "category",
            },
            yAxis: {
              title: {
                text: "",
              },
            },

            plotOptions: {
              column: {
                grouping: false,
                shadow: false,
                borderRadius: 4,
                borderWidth: 2,
                borderColor: "black",
              },
              series: {
                dataLabels: {
                  enabled: true,
                  format: "{point.y:.1f}",
                },
              },
            },
            legend: {
              shadow: false,
            },
            tooltip: {
              shared: true,
              // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
              pointFormat:
                '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}</b> kWh<br/>',
            },
            series: data?.getConsumptionHistory?.series,
            credits: {
              enabled: false,
            },
          }}
        />
        // </div>
      )}
    </div>
  );
};
