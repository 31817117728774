import React, { useState, useEffect, useRef } from "react";
import {
  WrapperForm,
  WrapperIndication,
  WrapperInputsButton,
  Button,
  Validations,
  WrapperAlert,
  shakeVertical,
} from "./style/changesPasswordForm.style";
import InputField from "../../../inputField/InputField";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { AuthContext } from "../../../../context/AuthProvider";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "../../../../controllers/usersControllers";
import { encryptData } from "../../../../utils/encryptDecrypt";

//multilanguage
import { useTranslation } from "react-i18next";

function ChangesPasswordForm() {
  const password = process.env.REACT_APP_ENCRYPT_DECRYPT_KEY;
  const { t } = useTranslation();
  const { inputField, setInputField } = React.useContext(AuthContext);
  const [errorButton, setErrorButton] = React.useState(false);
  const [errorPetition, setErrorPetition] = React.useState();
  const [changePassword] = useMutation(UPDATE_USER);
  const [validations, setValidations] = useState({
    currentPassword: false,
    length: false,
    uppercase: false,
    number: false,
    specialChar: false,
    equalPasswords: false,
  });
  const validationsOptions = [
    t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.ITEM1"),
    t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.ITEM2"),
    t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.ITEM3"),
    t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.ITEM4"),
    t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.ITEM5"),
    t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.ITEM6"),
  ];
  const buttonRef = useRef(null);

  const validatePassword = (password) => {
    setErrorButton(false);
    setErrorPetition();
    setValidations((prevValidations) => ({
      ...prevValidations,
      currentPassword: inputField?.currentPass !== "" ? true : false,
      length: password?.length >= 8,
      uppercase: /[A-Z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[!@#\$%\^&\*-_.]/.test(password),
      equalPasswords:
        inputField?.newPass !== undefined &&
        inputField?.newPass !== "" &&
        inputField?.confirmPass !== undefined &&
        inputField?.confirmPass !== "" &&
        inputField?.newPass === inputField?.confirmPass,
    }));
  };

  function passwordRequirements(objeto) {
    for (const propiedad in objeto) {
      if (objeto.hasOwnProperty(propiedad) && objeto[propiedad] !== true) {
        return false;
      }
    }
    return true;
  }

  const refreshState = () => {
    setTimeout(() => {
      setValidations({
        currentPassword: false,
        length: false,
        uppercase: false,
        number: false,
        specialChar: false,
        equalPasswords: false,
      });
      setErrorButton(false);
      setErrorPetition(false);
      setInputField({
        confirmPass: "",
        currentPass: "",
        newPass: "",
      });
    }, "2000");
  };
  const colorSpan = (position) => {
    const error = "#EDBABA";
    const success = "#B2E5B7";
    const normal = "#847F7F";

    if (inputField?.newPass !== undefined) {
      switch (position) {
        case 0:
          return validations.currentPassword ? success : error;
        case 1:
          return validations.length ? success : error;
        case 2:
          return validations.uppercase ? success : error;
        case 3:
          return validations.number ? success : error;
        case 4:
          return validations.specialChar ? success : error;
        case 5:
          return validations.equalPasswords ? success : error;
      }
    } else {
      return normal;
    }
  };
  const validationPass = () => {
    setErrorButton(false);
    setErrorPetition();
    setValidations((prevValidations) => ({
      ...prevValidations,
      equalPasswords:
        inputField?.newPass !== undefined &&
        inputField?.newPass !== "" &&
        inputField?.confirmPass !== undefined &&
        inputField?.confirmPass !== "" &&
        inputField?.newPass === inputField?.confirmPass,
    }));
  };

  useEffect(() => {
    if (inputField?.currentPass !== undefined) {
      setErrorButton(false);
      setErrorPetition();
      setValidations((prevValidations) => ({
        ...prevValidations,
        currentPassword: inputField?.currentPass !== "" ? true : false,
      }));
    }
  }, [inputField?.currentPass]);

  useEffect(() => {
    if (inputField?.newPass !== undefined) {
      validatePassword(inputField.newPass);
    }
  }, [inputField?.newPass]);

  useEffect(() => {
    if (inputField?.confirmPass !== undefined) {
      validationPass(inputField.confirmPass);
    }
  }, [inputField?.confirmPass]);

  const labelButton = () => {
    if (errorButton && errorPetition == undefined) {
      return t(
        "VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.MUST_COMPLETE_REQUIREMENTS"
      );
    } else if (!errorButton && errorPetition == undefined) {
      return t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.CHANGE_PASSWORD");
    } else if (!errorButton && !errorPetition) {
      return t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.SUCCESSFUL_CHANGE");
    } else if (errorButton && errorPetition) {
      return t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.WRONG_PASSWORD");
    }
  };

  async function userEdit(e) {
    if (buttonRef.current && !buttonRef.current.disabled) {
      buttonRef.current.disabled = true;
      const currentPassword = await encryptData(
        inputField?.currentPass,
        password
      );
      const newPassword = await encryptData(inputField?.newPass, password);
      const ConfirmPassword = await encryptData(
        inputField?.confirmPass,
        password
      );

      if (passwordRequirements(validations)) {
        try {
          changePassword({
            variables: {
              id: parseInt(localStorage.getItem("np")),
              currentPassword: currentPassword,
              newPassword: newPassword,
              confirmPassword: ConfirmPassword,
            },
          })
            .then((response) => {
              setErrorButton(false);
              setErrorPetition();
              refreshState();
              setTimeout(() => {
                buttonRef.current.disabled = false;
              }, 2000);
            })
            .catch((err) => {
              setErrorButton(true);
              setErrorPetition(true);
              setTimeout(() => {
                buttonRef.current.disabled = false;
              }, 2000);
            });
        } catch (err) {}
      } else {
        e.preventDefault();
        setValidations((prevValidations) => ({
          ...prevValidations,
          errorButton: true,
        }));
        setTimeout(() => {
          buttonRef.current.disabled = false;
        }, 2000);
      }
    }
  }

  return (
    <React.Fragment>
      <WrapperForm>
        <WrapperIndication>
          <p>
            {t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.DESCRIPTION_BOLD")}
          </p>

          {validationsOptions?.map((item, key) => (
            <Validations background={colorSpan(key)}>
              <div>
                <span>•</span>
                {item}
              </div>
            </Validations>
          ))}
        </WrapperIndication>
        <WrapperInputsButton>
          <InputField
            label={t(
              "VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.CURRENT_PASSWORD"
            )}
            type="password"
            name="currentPass"
          />
          <InputField
            label={t(
              "VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.NEW_PASSWORD"
            )}
            type="password"
            name="newPass"
          />
          <InputField
            label={t(
              "VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.CONFIRM_PASSWORD"
            )}
            type="password"
            name="confirmPass"
          />
          <Button
            error={errorButton}
            onClick={(e) => {
              userEdit(e);
            }}
            ref={buttonRef}
            onMouseDown={(e) => {
              if (!passwordRequirements(validations)) {
                e.currentTarget.classList.add("shake");
                setErrorButton(true);
              }
            }}
            onMouseUp={(e) => e.currentTarget.classList.remove("shake")}
          >
            {labelButton()}
            {/*  {errorButton ? "Complete los requerimientos" : "Cambiar contraseña"} */}
          </Button>
        </WrapperInputsButton>
      </WrapperForm>
    </React.Fragment>
  );
}
export default ChangesPasswordForm;
