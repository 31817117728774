import { useEffect, useState, useContext, useRef } from "react";
import { Devices } from "../../../../../constants/devices_types";
import Loading_devices from "../../../../../components/loading/Loading_devices";
import { AuthContext } from "../../../../../context/AuthProvider";
import { changeNamePluggin } from "../../../../../utils/helpers";

import Tooltip from "@mui/material/Tooltip";

//Multilanguage
import { useTranslation } from "react-i18next";

const NewHSC = ({
  hsc,
  project,
  serial,
  dataSocket,
  sendMessage,
  controllerStatus,
}) => {
  const { t } = useTranslation();
  const { alias, variables, Category, name } = hsc;
  const { category, sub_category } = Category;
  const { item_id, status, type, AccionTexto, Modo, EstadoTexto, onLine } =
    variables;
  const { AccionTextoId, ModoId } = item_id;
  const [libre, setLibre] = useState(EstadoTexto === "Libre" ? true : false);
  const [accionTexto, setAccionTexto] = useState(AccionTexto);
  const [stateMode, setStateMode] = useState(Modo);
  const [auto, setAuto] = useState(Modo === "Auto" ? true : false);
  const [manual, setManual] = useState(Modo === "Manual" ? true : false);
  const [apagado, setApagado] = useState(Modo === "Apagado" ? true : false);
  const [socketSnackbar, setSocketSnackbar] = useState(null);
  const socketInterval = useRef(null);
  const { permissionsRoleUser, setSuccessMessage, setErrorMessage } =
    useContext(AuthContext);

  useEffect(() => {
    if (
      dataSocket.category === "generic_io" &&
      dataSocket.event_name === "EstadoTexto"
    ) {
      if (dataSocket.state === "Ocupado") {
        setLibre(false);
        clearInterval(socketInterval.current);
      }
    }

    if (
      dataSocket.category === "generic_io" &&
      dataSocket.event_name === "AccionTexto"
    ) {
      if (dataSocket.state === "Libre") {
        setAccionTexto(dataSocket.state);
        setLibre(dataSocket.state);
        clearInterval(socketInterval.current);
        setSocketSnackbar(true);
        clearInterval(socketInterval.current);
      } else if (dataSocket.state === "Scan") {
        setAccionTexto(dataSocket.state);
        clearInterval(socketInterval.current);
        setSocketSnackbar(true);
        clearInterval(socketInterval.current);
      } else if (dataSocket.state === "puerta abierta") {
        setAccionTexto(dataSocket.state);
        clearInterval(socketInterval.current);
        setSocketSnackbar(true);
        clearInterval(socketInterval.current);
      } else if (dataSocket.state === "ocupado") {
        setAccionTexto(dataSocket.state);
        clearInterval(socketInterval.current);
        setSocketSnackbar(true);
        clearInterval(socketInterval.current);
      }
    }

    if (
      dataSocket.category === "generic_io" &&
      dataSocket.event_name === "Modo"
    ) {
      if (dataSocket.state === "Auto") {
        setAuto(dataSocket.state);
        setApagado(false);
        setManual(false);
        setStateMode("Auto");
        clearInterval(socketInterval.current);
      }
      if (dataSocket.state === "Apagado") {
        setApagado(dataSocket.state);
        setManual(false);
        setAuto(false);
        setStateMode("Apagado");
        clearInterval(socketInterval.current);
      }
      if (dataSocket.state === "Manual") {
        setManual(dataSocket.state);
        setAuto(false);
        setApagado(false);
        setStateMode("Manual");
        clearInterval(socketInterval.current);
      }
    }
  }, [dataSocket]);

  useEffect(() => {
    if (socketSnackbar) {
      setSuccessMessage(true);
      setSocketSnackbar(null);
    } else if (socketSnackbar === false) {
      setErrorMessage(true);
      setAccionTexto(AccionTexto);
      setSocketSnackbar(null);
    }
  }, [socketSnackbar]);

  const SocketTimeValidationResponse = () => {
    clearInterval(socketInterval.current);
    let count = 0;
    if (onLine === false && controllerStatus === false) {
    } else {
      socketInterval.current = setInterval(() => {
        count++;
        if (count > 15) {
          clearInterval(socketInterval.current);
          setSocketSnackbar(false);
          setSocketSnackbar(null);
        }
      }, 1000);
    }
  };

  const handleChangesOptions = (e) => {
    sendMessage({
      serial,
      channel: serial,
      id: AccionTextoId,
      eventType: "plugin_mode",
      value: "Libre",
    });
    SocketTimeValidationResponse();
  };

  const handleChangesAutoMode = () => {
    setAuto(true);
    setApagado(false);
    setManual(false);
    sendMessage({
      serial,
      channel: serial,
      id: ModoId,
      eventType: "plugin_mode",
      value: "Auto",
    });
    SocketTimeValidationResponse();
  };

  const handleChangesOffMode = () => {
    setApagado(true);
    setManual(false);
    setAuto(false);
    sendMessage({
      serial,
      channel: serial,
      id: ModoId,
      eventType: "plugin_mode",
      value: "Apagado",
    });
    SocketTimeValidationResponse();
  };

  const handleChangesManualMode = () => {
    setManual(true);
    setAuto(false);
    setApagado(false);
    sendMessage({
      serial,
      channel: serial,
      id: ModoId,
      eventType: "plugin_mode",
      value: "Manual",
    });
    SocketTimeValidationResponse();
  };

  const translatingHeaderCardPlugin = (alias) => {
    const pluginTitle = typeof alias === "string" ? alias : "";
    if (pluginTitle?.toLowerCase() === "plugin ocupacion") {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.PLUGIN_OCCUPATION");
    } else if (pluginTitle?.toLowerCase() === "aula inteligente") {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.PLUGIN_SMART");
    } else {
      return pluginTitle;
    }
  };

  const translatingAccionTexto = (accionTexto) => {
    const systemStatus = typeof accionTexto === "string" ? accionTexto : "";
    if (systemStatus.toLowerCase() === "libre") {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.AVAILABLE");
    } else if (systemStatus.toLowerCase() === "scan") {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.SCAN");
    } else if (systemStatus.toLowerCase() === "puerta abierta") {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.OPEN_DOOR");
    } else if (systemStatus.toLowerCase() === "ocupado") {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.BUSY");
    } else {
      return systemStatus;
    }
  };

  return (
    <>
      <div className="_hsc_container">
        <div
          className={`_hsc_container_title_status ${
            onLine && controllerStatus ? null : "_offline_hsc"
          }`}
        >
          <div class="_hsc__title">
            <span>{translatingHeaderCardPlugin(alias)}</span>
          </div>
          <div className="_hsc_status">
            <span
              className={`${
                onLine && controllerStatus
                  ? "__icon_wifi_hsc"
                  : "__icon_wifi_offline_hsc"
              }`}
            >
              <i class="fas fa-wifi"></i>
            </span>
          </div>
        </div>

        {/* status home */}
        <div
          className={`_hsc_ocupation ${
            variables.online ? "_loading_hsc_other" : false
          }`}
        >
          <div className="_hsc_home_img">
            <span>
              <img
                src={variables.online ? Devices.DESOCUPADA : Devices.OCUPADA}
                alt="home"
                width="70"
                height="70"
              />
            </span>
          </div>
          <div className="_hsc_home_buttons">
            <div
              className="_free_container"
              onClick={
                permissionsRoleUser.permissionsRole?.AreaDispMan
                  ? (e) => {
                      handleChangesOptions(e);
                    }
                  : false
              }
            >
              <div className={accionTexto === "Libre" ? "__free_bg" : "__free"}>
                <span
                  onClick={
                    permissionsRoleUser.permissionsRole?.AreaDispMan
                      ? (e) => {
                          handleChangesOptions(e);
                        }
                      : false
                  }
                >
                  {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.AVAILABLE")}
                </span>
              </div>
            </div>

            <Tooltip
              title={t(
                "VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.BUSY_TOOLTIP"
              )}
            >
              <div className="_busy_container">
                <div className={libre === false ? "__busy_bg" : "__busy"}>
                  {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.BUSY")}
                </div>
              </div>
            </Tooltip>
          </div>
        </div>

        <div className="__loading_hsc">
          {variables.online ? <Loading_devices /> : false}
        </div>

        {/* status system */}
        <div
          className={`_hsc_status_system ${"" ? "_loading_hsc_other" : false}`}
        >
          <div className="_hsc_status_system_title">
            <span>
              {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.SYSTEM_STATUS")}
            </span>
          </div>
          <>
            <div
              className={
                libre ? "__status_system_free" : "__status_system_busy"
              }
            >
              <span className="__status_word">
                {translatingAccionTexto(accionTexto)}
              </span>
            </div>
          </>
        </div>

        <div
          className={`_hsc_mode ${
            variables?.online ? "_loading_hsc_other" : false
          }`}
        >
          <div className="_hsc_mode_title">
            <span>{t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.MODE")}</span>
          </div>
          <div className="__mode_container">
            <span
              className={
                manual ? "__mode_item_manual_bg" : "__mode_item_manual"
              }
              onClick={
                permissionsRoleUser?.permissionsRole?.AreaDispMan
                  ? (e) => {
                      handleChangesManualMode(e);
                    }
                  : false
              }
            >
              {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.MANUAL")}
            </span>
            <span
              className={apagado ? "__mode_item_off_bg" : "__mode_item_off"}
              onClick={
                permissionsRoleUser?.permissionsRole?.AreaDispMan
                  ? (e) => {
                      handleChangesOffMode(e);
                    }
                  : false
              }
            >
              {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.OFF")}
              {/* {changeNamePluggin(project.id)} */}
            </span>
            <span
              className={auto ? "__mode_item_auto_bg" : "__mode_item_auto"}
              onClick={
                permissionsRoleUser?.permissionsRole?.AreaDispMan
                  ? (e) => {
                      handleChangesAutoMode(e);
                    }
                  : false
              }
            >
              {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.AUTO")}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewHSC;
