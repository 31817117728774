import React, { useEffect, useState } from "react";

//Material UI components & styles
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";

//GraphQl Hooks & Queries
import { useQuery } from "@apollo/client";
import { GET_BATTERY_NEW_DEVICES } from "../../../../controllers/reportController";

//Helpers
import { formatDateLocal } from "../../../../utils/helpers";
import {
  handleBatteryLevel,
  handleBatteryLevelColor,
} from "../utils/helperBattery";
import GraphBatteryLevel from "../../../../components/devices/GraphBatteryLevel/GraphBatteryLevel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  titleConainer: {
    marginLeft: theme.spacing(1),
    color: "white",
    paddingTop: 12,
    flex: 1,
  },
  title: {
    fontSize: 15,
  },
  spacingClose: {
    marginRight: 30,
    padding: "5px 15px",
    cursor: "pointer",
    borderRadius: 50,
    "&:hover": {
      backgroundColor: "#069C08",
    },
  },
}));

const Battery_Newlevel = ({
  openBattery,
  handleCloseBattery,
  name,
  id_Battery,
}) => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_BATTERY_NEW_DEVICES, {
    variables: { itemId: id_Battery },
  });

  const [dataBattery, setDataBattery] = useState();

  useEffect(() => {
    if (!loading && !error && data) {
      const batteryData = data.getAllNewDeviceByBatteryLevel?.data;
      if (batteryData !== undefined && batteryData.length !== 0) {
        setDataBattery(batteryData);
      }
    }
  }, [data]);

  return (
    <Dialog
      fullWidth
      fullScreen
      open={openBattery}
      onClose={handleCloseBattery}
      TransitionComponent={Transition}
    >
      <div className="header_model_battery_report">
        <span className={classes.spacingClose} onClick={handleCloseBattery}>
          X
        </span>
        <span
          title={`Reporte de bateria - ${localStorage.getItem(
            "nameProject"
          )} - ${name}`}
          className="header_model_battery_report_title"
        >
          Reporte de bateria - {`${localStorage.getItem("nameProject")}`} -{" "}
          {name}
        </span>
      </div>
      <div className="battery_devices">
        {!dataBattery || dataBattery.length === 0 ? (
          <div className="battery_device_empty">No hay datos</div>
        ) : (
          <>
            <GraphBatteryLevel data={data} typeGateway={"horus_700"} />
            <div className="battery_device_title_container">
              <div className="battery_device_title_date">Fecha</div>
              <div className="battery_device_title_level">Nivel</div>
            </div>
            {[...dataBattery]?.reverse()?.map((i) => (
              <div className="battery_device_container" key={i?.id}>
                <div className="battery_device_date">
                  {formatDateLocal(i?.x)}
                </div>
                <div className="battery_device_level">
                  <span className="battery_device_averange">{i?.y}%</span>
                  <span
                    className={`battery_device_level_icon ${handleBatteryLevelColor(
                      i?.y
                    )}`}
                  >
                    {handleBatteryLevel(i?.y)}
                  </span>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </Dialog>
  );
};

export default Battery_Newlevel;
