//React Hooks
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";

//Apollo and Queries
import { GET_DEVICE_BY_TEMPERATURE } from "../../controllers/reportController";
import { useLazyQuery } from "@apollo/client";

//Highchart
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

//Material UI
import { withStyles } from "@material-ui/core/styles";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  TableCell,
} from "@material-ui/core";

//Components
import AreaSelector from "../areaSelector/AreaSelector";
import Loading from "../loading/Loading";
import { ReactDateRangePicker } from "../reactDateRange";

//Multilanguage
import { useTranslation } from "react-i18next";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    fontWeight: "bold",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Temperature = ({ RoomId, project, typeGateway, NewRoomId }) => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const { t } = useTranslation();
  const {
    dateCalendar,
    setDateCalendar,
    setIsOpenCalendarCustom,
    dateCalendarTemp,
    calendarDatePicker, // calendar
    setCalendarDatePicker, // setCalendar
    setCalendarDatePickerToday,
    calendarDatePickerToday,
    globalCalendarDay,
    setIdHeaderOcupation,
    idHeaderOcupation,
  } = useContext(AuthContext);

  const init_time = (time) => {
    const time_now = new Date().getHours();
    if (time_now === 15 && time === "15:00") {
      const time_actual = new Date().getTime() - 86400000;
      return new Date(time_actual);
    }
    return new Date();
  };

  const [roomId, setRoomId] = useState(RoomId);
  const [newRoomId, setNewRoomId] = useState(NewRoomId);
  const [TypeGateway, setTypeGateway] = useState(typeGateway);
  const [parameterMax, setParameterMax] = useState(new Date());
  const [parameterMin, setParameterMin] = useState(
    init_time(project?.start_time)
  );

  const [funcCalendarAction, { data: funcCalendar, loading: isCalendar }] =
    useLazyQuery(GET_DEVICE_BY_TEMPERATURE);

  const averageTemperature = funcCalendar?.getDeviceByTemperature?.data?.filter(
    (element) => element?.temperatureAvarage != undefined
  );

  useEffect(() => {
    if (globalCalendarDay) {
      handlerEpochTimeConverter();
    }
  }, [globalCalendarDay]);

  useEffect(() => {
    handlerEpochTimeConverter();
  }, [idHeaderOcupation, roomId, newRoomId]);

  const handlerEpochTimeConverter = () => {
    if (project?.start_time) {
      let epochTime = 60 * 60 * 1000;

      let startTimeZone =
        globalCalendarDay?.toString()?.substring(0, 16) +
        `${project?.start_time}:00 GMT` +
        project?.time_zone;

      let endTimeZone = new Date(startTimeZone)?.getTime() + (86400000 - 60000);

      let param =
        globalCalendarDay.toString()?.substring(0, 16) +
        `${project?.start_time}:00 GMT`;

      const parameter_min =
        new Date(param).getTime() -
        parseInt(project?.time_zone, 10) * epochTime;

      const parameter_max = new Date(endTimeZone).getTime();

      setParameterMax(parameter_max);
      setParameterMin(parameter_min);

      handlerTemperatureQuery(parameter_min, parameter_max);
    }
  };

  const handlerTemperatureQuery = (parameter_min, parameter_max) => {
    funcCalendarAction({
      variables: {
        RoomId: parseInt(roomId),
        NewRoomId: newRoomId,
        hourStart: `${new Date(parameter_min).getTime()}`,
        hourEnd: `${new Date(parameter_max).getTime()}`,
        typeGateway: TypeGateway,
      },
      fetchPolicy: "no-cache",
    });
  };

  // // With start time
  // const with_start_time = () => {
  //   let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
  //   let hour_actual = new Date(calendarDatePicker).getHours();
  //   let time_use = new Date(calendarDatePicker).getTime();
  //   let data_calendar = new Date(time_use);
  //   let time;

  //   if (hour_actual < 15 && project?.start_time === "15:00") {
  //     time = new Date(data_calendar.getTime() - 86400000);
  //     setCalendarDatePicker(time);
  //     setCalendarDatePickerToday(time);
  //   } else {
  //     time = calendarDatePickerToday;
  //     setCalendarDatePicker(time);
  //     setCalendarDatePickerToday(time);
  //   }

  //   let day_time_zone =
  //     time.toString().substring(0, 16) +
  //     `${project.start_time}:00 GMT` +
  //     project.time_zone;
  //   let day_time_end_zone =
  //     new Date(day_time_zone).getTime() + (86400000 - 60000);
  //   let param =
  //     time.toString().substring(0, 16) + `${project.start_time}:00 GMT`;

  //   const parameter_min =
  //     new Date(param).getTime() -
  //     parseInt(project?.time_zone, 10) * HORA_EN_MILISEGUNDO;

  //   const parameter_max = new Date(day_time_end_zone).getTime();

  //   //Parameter StartDate
  //   setParameterMin(parameter_min);
  //   //Parameter EndDate
  //   setParameterMax(parameter_max);

  //   funcCalendarAction({
  //     variables: {
  //       RoomId: parseInt(RoomId),
  //       NewRoomId: NewRoomId,
  //       hourStart: `${new Date(parameter_min).getTime()}`,
  //       hourEnd: `${new Date(parameter_max).getTime()}`,
  //       typeGateway: typeGateway,
  //     },
  //     fetchPolicy: "no-cache",
  //   });
  // };

  // // Without start time
  // const without_start_time = () => {
  //   let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
  //   let day_time_end_zone =
  //     dateCalendar?.toString()?.substring(0, 16) +
  //     "23:59:59 GMT" +
  //     project?.time_zone;
  //   let param = dateCalendar?.toString()?.substring(0, 16) + "00:00:00 GMT";

  //   let parameter_min =
  //     new Date(param)?.getTime() -
  //     parseInt(project?.time_zone, 10) * HORA_EN_MILISEGUNDO;

  //   const parameter_max = new Date(day_time_end_zone)?.getTime();

  //   //Parameter StartDate
  //   setParameterMin(parameter_min);
  //   //Parameter EndDate
  //   setParameterMax(parameter_max);
  // };

  // const handleQueryReport = () => {
  //   if (project?.start_time) {
  //     with_start_time();
  //   } else {
  //     without_start_time();
  //   }
  // };

  // const back = () => {
  //   if (project?.start_time) {
  //     const backday = calendarDatePicker; // Obtenemos el dia

  //     let day_time_zone =
  //       new Date(backday)?.toString()?.substring(0, 16) +
  //       `${project?.start_time}:00 GMT` +
  //       project?.time_zone; // Le damos el formato de e.g "Wed Jan 31 2024 00:00:00 GTM", dependiendo de la hora del start time

  //     let day_time_end_zone =
  //       new Date(day_time_zone)?.getTime() - (86400000 - 60000); // A la hora que calculamos anterior le sumamos 1 dias menos 1 minuto

  //     //Parámetro inicial
  //     setParameterMin(day_time_end_zone);
  //     //parámetro final
  //     setParameterMax(day_time_end_zone + (86400000 - 60000));

  //     // set el nuevo valor de calendar
  //     setCalendarDatePicker(new Date(day_time_end_zone));
  //     setCalendarDatePickerToday(new Date(day_time_end_zone));

  //     funcCalendarAction({
  //       variables: {
  //         RoomId: parseInt(RoomId),
  //         NewRoomId: NewRoomId,
  //         hourStart: `${day_time_end_zone - 60000}`,
  //         hourEnd: `${day_time_end_zone + (86400000 - 120000)}`,
  //         typeGateway: typeGateway,
  //       },
  //       fetchPolicy: "no-cache",
  //     });
  //   } else {
  //     const backday = dateCalendarTemp;
  //     let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
  //     const new_backday = new Date(backday);

  //     let day_time_end_zone =
  //       new Date(new_backday).toString().substring(0, 16) +
  //       "23:59:59 GMT" +
  //       project?.time_zone;
  //     let param =
  //       new Date(new_backday).toString().substring(0, 16) + "00:00:00 GMT";

  //     let tempPara =
  //       new Date(param).getTime() -
  //       parseInt(project?.time_zone, 10) * HORA_EN_MILISEGUNDO;

  //     const temEndDate = new Date(day_time_end_zone).getTime();

  //     setDateCalendar(new_backday);
  //     //openDateCusom
  //     setIsOpenCalendarCustom(false);
  //     //Parámetro inicial
  //     setParameterMin(tempPara);
  //     //parámetro final
  //     setParameterMax(temEndDate);
  //   }
  // };

  // const next = () => {
  //   if (project?.start_time) {
  //     const nextday = calendarDatePicker; // Obtenemos el dia

  //     let day_time_zone =
  //       new Date(nextday).toString().substring(0, 16) +
  //       `${project?.start_time}:00 GMT` +
  //       project?.time_zone; // Le damos el formato de e.g "Wed Jan 31 2024 00:00:00 GTM", dependiendo de la hora del start time

  //     let day_time_end_zone = new Date(day_time_zone).getTime() + 86400000; // A la hora que calculamos anterior le sumamos 1 dias menos 1 minuto

  //     //Parámetro inicial
  //     setParameterMin(day_time_end_zone);
  //     //parámetro final
  //     setParameterMax(day_time_end_zone + (86400000 - 60000));

  //     // set el nuevo valor de calendar
  //     setCalendarDatePicker(new Date(day_time_end_zone));
  //     setCalendarDatePickerToday(new Date(day_time_end_zone));

  //     funcCalendarAction({
  //       variables: {
  //         RoomId: parseInt(RoomId),
  //         NewRoomId: NewRoomId,
  //         hourStart: `${day_time_end_zone}`,
  //         hourEnd: `${day_time_end_zone + (86400000 - 60000)}`,
  //         typeGateway: typeGateway,
  //       },
  //       fetchPolicy: "no-cache",
  //     });
  //   } else {
  //     const nextday = dateCalendarTemp;
  //     let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
  //     const new_nextday = new Date(nextday);

  //     let day_time_end_zone =
  //       new Date(new_nextday).toString().substring(0, 16) +
  //       "23:59:59 GMT" +
  //       project?.time_zone;
  //     let param =
  //       new Date(new_nextday).toString().substring(0, 16) + "00:00:00 GMT";

  //     let tempPara =
  //       new Date(param).getTime() -
  //       parseInt(project?.time_zone, 10) * HORA_EN_MILISEGUNDO;

  //     const temEndDate = new Date(day_time_end_zone).getTime();

  //     setDateCalendar(new_nextday);
  //     //openDateCusom
  //     setIsOpenCalendarCustom(false);
  //     //Parámetro inicial
  //     setParameterMin(tempPara);
  //     //parámetro final
  //     setParameterMax(temEndDate);
  //   }
  // };

  const changeRoomId = (id) => {
    setRoomId(id);
    setIdHeaderOcupation(id);
    const typeGateway = JSON.parse(localStorage.getItem("areas"))
      ?.filter((i) => parseInt(i.id) === parseInt(id))
      .map((i) => i.typeGateway);

    const newRoomId = JSON.parse(localStorage.getItem("areas"))
      ?.filter((i) => parseInt(i.id) == id)
      .map((i) => i.newRoomId);
    setTypeGateway(typeGateway[0]);
    setNewRoomId(newRoomId[0]);
  };

  // useEffect(() => {
  //   if (project?.start_time) {
  //     with_start_time();
  //   } else {
  //     without_start_time();
  //   }
  // }, []);

  if (isCalendar) {
    return <Loading />;
  }

  const getNormalTime = (epoch) => {
    const fiveHours = 18000000;
    return new Date(epoch - fiveHours).toISOString().slice(11, 16);
  };

  const handleProjectTime = (time) => {
    if (time === "0:00" || time === "00:00") {
      return 0;
    } else if (time === "15:00") {
      return 15;
    }
  };

  return (
    <div className="occupation_report_container">
      {/* <DatePicker
        type="calendar"
        component={"temperature"}
        handleQuery={handleQueryReport}
        back={back}
        next={next}
        selectOption={{ changeInside: false, changeRoomId, stateRoomId: 0 }}
        timerProject={project?.start_time}
        typeGateway={typeGateway}
      /> */}
      <div className="Datepicker_AreaSelector_Container">
        <ReactDateRangePicker
          selectTypeRange="d"
          closingTime={handleProjectTime(project?.start_time)}
          closingType="n"
        />
        <AreaSelector changeRoomId={changeRoomId} />
      </div>

      {funcCalendar?.getDeviceByTemperature?.data?.length === 0 ? (
        <div className="date_vacio_report_measurer">
          <span>
            <i class="fas fa-exclamation-triangle"></i>
            {t(
              "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_MODAL_TEMPERATURE.NO_DATA"
            )}
          </span>
        </div>
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            title: {
              text: "",
            },

            plotOptions: {
              area: {
                fillOpacity: 0.4,
                marker: {
                  enabled: true,
                  symbol: "circle",
                },
              },
              series: {
                step: true,
                states: {
                  inactive: {
                    enabled: false,
                  },
                },
                turboThreshold: 0,
                marker: {
                  enabled: true,
                  radius: 3,
                  symbol: "circle",
                },
                label: {
                  enabled: false,
                },
              },
            },
            legend: {
              align: innerWidth <= 980 ? "center" : "right",
              verticalAlign: innerWidth <= 980 ? "bottom" : "top",
              layout: innerWidth <= 980 ? "horizontal" : "vertical",
              x: 0,
              y: 0,
            },
            xAxis: {
              type: "datetime",
              min: parameterMin, // StartDate
              max: parameterMax, // EndDate
              tickInterval: 3600000,
              labels: {
                format: "{value:%H:%M}",
              },
            },
            yAxis: [
              {
                // Eje principal Humedad
                gridLineWidth: 0,
                title: {
                  text: t(
                    "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_MODAL_TEMPERATURE.HUMIDITY"
                  ),
                },
                labels: {
                  format: "{value} %",
                },
              },
              {
                // Eje secundario temperatura
                min: 15,
                max: 40,
                gridLineWidth: 0,
                title: {
                  text: t(
                    "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_MODAL_TEMPERATURE.TEMPERATURE"
                  ),
                  style: {
                    color: Highcharts?.getOptions().colors[1],
                  },
                },
                type: "spline",
                labels: {
                  format: "{value} °C",
                  /* style: {
                    color: Highcharts.getOptions().colors[1]
                  } */
                },
                opposite: true,
              },
              {
                // Eje Y oculto para ocupacion
                min: 0,
                max: 1,
                title: null,
                labels: {
                  format: "{value}",
                  enabled: false,
                },
                gridLineWidth: 0,
                minorGridLineWidth: 0,
              },
            ],

            time: {
              timezoneOffset: -(Number(project?.time_zone) * 60),
            },
            chart: {
              zoomType: "x",
              type: "line", //lineas curvas con (spline)
            },
            series: funcCalendar?.getDeviceByTemperature?.data,
            tooltip: {
              xDateFormat: "%H:%M:%S",
              headerFormat: "<b>{point.key}</b><br>",
              //valueSuffix: "C°",
            },
            credits: {
              enabled: false,
            },
          }}
        />
      )}
      <div className="_temperature_container_tables_">
        {averageTemperature
          ? averageTemperature?.map((i, key) => (
              <TableContainer component={Paper}>
                <div className="__report_temperature_table_total_title__">
                  {i?.name}
                </div>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_MODAL_TEMPERATURE.MAX_TEMPERATURE"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_MODAL_TEMPERATURE.MIN_TEMPERATURE"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_MODAL_TEMPERATURE.MAX_HOUR"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_MODAL_TEMPERATURE.MIN_HOUR"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {t(
                          "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_MODAL_TEMPERATURE.AVERAGE_TEMPERATURE"
                        )}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell align="center">
                        {i?.temperatureAvarage &&
                        i?.temperatureAvarage?.maxTemp !== undefined
                          ? `${i?.temperatureAvarage?.maxTemp} ${i?.tooltip?.valueSuffix}`
                          : "----"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {i.temperatureAvarage &&
                        i?.temperatureAvarage?.minTemp !== undefined
                          ? `${i?.temperatureAvarage?.minTemp} ${i?.tooltip?.valueSuffix}`
                          : "----"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {i.temperatureAvarage &&
                        i?.temperatureAvarage?.maxTempTime !== undefined
                          ? `${getNormalTime(
                              i?.temperatureAvarage?.maxTempTime
                            )}`
                          : "----"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {i.temperatureAvarage &&
                        i?.temperatureAvarage?.minTempTime !== undefined
                          ? `${getNormalTime(
                              i?.temperatureAvarage?.minTempTime
                            )}`
                          : "----"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {i.temperatureAvarage &&
                        i?.temperatureAvarage?.tempAvarage !== undefined
                          ? `${i?.temperatureAvarage?.tempAvarage} ${i?.tooltip?.valueSuffix}`
                          : "----"}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ))
          : false}
      </div>
    </div>
  );
};
export default Temperature;
