import gql from "graphql-tag";

export const PDF_GENERAL_OCUPATION_REPORT = gql`
  mutation pdfGeneralOcupationReport(
    $image: [String]
    $data: JSON
    $startTime: String
    $finishTime: String
    $nameProject: String
    $userName: String
    $imageProject: String
    $datePrint: String
  ) {
    pdfGeneralOcupationReport(
      image: $image
      data: $data
      startTime: $startTime
      finishTime: $finishTime
      nameProject: $nameProject
      userName: $userName
      imageProject: $imageProject
      datePrint: $datePrint
    )
  }
`;

export const PDF_OCUPATION_BY_ROOM = gql`
  mutation pdfOcupationByRoom(
    $image: [String]
    $data: JSON
    $prefix_currency: String
    $kwh_cost: Float
    $startTime: String
    $finishTime: String
    $nameProject: String
    $userName: String
    $imageProject: String
    $datePrint: String
  ) {
    pdfOcupationByRoom(
      image: $image
      data: $data
      prefix_currency: $prefix_currency
      kwh_cost: $kwh_cost
      startTime: $startTime
      finishTime: $finishTime
      nameProject: $nameProject
      userName: $userName
      imageProject: $imageProject
      datePrint: $datePrint
    )
  }
`;

export const PDF_MEASURE_GENERAL = gql`
  mutation pdfMeasureGeneral(
    $image: [String]
    $data: JSON
    $startTime: String
    $date_range: [String]
    $finishTime: String
    $nameProject: String
    $userName: String
    $imageProject: String
    $datePrint: String
  ) {
    pdfMeasureGeneral(
      image: $image
      data: $data
      startTime: $startTime
      date_range: $date_range
      finishTime: $finishTime
      nameProject: $nameProject
      userName: $userName
      imageProject: $imageProject
      datePrint: $datePrint
    )
  }
`;

export const PDF_MEASURE_PHASES = gql`
  mutation pdfMeasurePhases(
    $image: [String]
    $data: JSON
    $date_range: [String]
    $startTime: String
    $finishTime: String
    $nameProject: String
    $userName: String
    $imageProject: String
    $datePrint: String
  ) {
    pdfMeasurePhases(
      image: $image
      data: $data
      startTime: $startTime
      date_range: $date_range
      finishTime: $finishTime
      nameProject: $nameProject
      userName: $userName
      imageProject: $imageProject
      datePrint: $datePrint
    )
  }
`;

export const PDF_MACROMETER = gql`
  mutation pdfMacrometer(
    $serial: String
    $location: String
    $year: Int
    $fullYear: Int
    $month: Int
    $data: JSON
    $startTime: String
    $startTimeEpoch: Int
    $finishTime: String
    $finishTimeEpoch: Int
    $nameProject: String
    $userName: String
    $imageProject: String
    $datePrint: String
  ) {
    pdfMacrometer(
      serial: $serial
      location: $location
      year: $year
      fullYear: $fullYear
      month: $month
      data: $data
      startTime: $startTime
      startTimeEpoch: $startTimeEpoch
      finishTime: $finishTime
      finishTimeEpoch: $finishTimeEpoch
      nameProject: $nameProject
      userName: $userName
      imageProject: $imageProject
      datePrint: $datePrint
    )
  }
`;

export const EXCEL_GENERAL_OCUPATION_REPORT = gql `
mutation Mutation($projectId: Int!, $startTime: Date!, $endTime: Date!, $dateRange: [Date], $timeZone: String) {
  generateExcelReport(ProjectId: $projectId, start_time: $startTime, end_time: $endTime, date_range: $dateRange, time_zone: $timeZone)
}
`;

export const EXCEL_MACROMETER_REPORT = gql `
query Query($serial: String!, $startTime: String!, $endTime: String!) {
  getConsumptionReportFile(serial: $serial, startTime: $startTime, endTime: $endTime)
    }
`;  
