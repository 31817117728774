import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useMutation } from "@apollo/client";
import { UPDATE_CONTROLLER } from "../../controllers/roomControllers";
import { useTranslation } from "react-i18next";

export const ModelEditController = ({ open, handlerClose, data, refetch }) => {
  const projects = JSON.parse(localStorage.getItem("project"));
  const projectIdAssociated = localStorage.getItem("id");
  const { t } = useTranslation();
  const [updatedController] = useMutation(UPDATE_CONTROLLER);
  const [editName, setEditName] = useState(false);
  const [editProject, setEditProject] = useState(false);
  const [errors, setErrors] = useState({ name: false, projectId: false });
  const [dataEdit, setDataEdit] = useState({
    name: data?.item?.name,
    projectId: Number(projectIdAssociated),
  });

  const handlerEditName = () => {
    setEditName(!editName);
  };
  const handlerEditProject = () => {
    setEditProject(!editProject);
  };

  const handlerEditTextName = (e) => {
    setDataEdit({ ...dataEdit, name: e.target.value });
    setErrors((prev) => ({ ...prev, name: false }));
  };

  const handlerEditIdProject = (e) => {
    const { value } = e.target;
    setDataEdit({ ...dataEdit, projectId: value });
    setErrors((prev) => ({ ...prev, projectId: false }));
  };

  const validateFields = () => {
    const nameError = dataEdit.name.trim() === "";
    const projectIdError = !dataEdit.projectId;

    setErrors({ name: nameError, projectId: projectIdError });
    return !(nameError || projectIdError);
  };

  const updated = () => {
    if (!validateFields()) {
      return console.error("error no estan validados los campos");
    }
    try {
      updatedController({
        variables: {
          updateControllerId: data?.item?.id,
          typeGateway: data?.item?.typeGateway,
          data: {
            name: dataEdit.name,
            ProjectId: dataEdit.projectId,
          },
        },
      })
        .then((resul) => {
          if (resul.data && resul.data.error === "validation error") {
            console.error("Error de validacion", resul.data.message);
          } else {
            setTimeout(() => {
              refetch();
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const isValid = (value) =>
    value !== "" && value !== undefined && value !== null;

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "25%",
          maxHeight: 500,
          borderRadius: "8px",
        },
      }}
      open={open}
      maxWidth="xs"
    >
      <DialogTitle
        sx={{
          background: "#3f9948",
          color: "white",
          fontFamily: "Montserrat, sans-serif",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: "3px",
          fontSize: "14px",
          height: "20px",
        }}
      >
        <b>{t("COMPONENT_EDIT_CONTROLLER.TITLE")}</b>
        <Button sx={{ color: "white" }} onClick={handlerClose}>
          {" "}
          X
        </Button>
      </DialogTitle>

      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        {isValid(data?.item?.firmware) && (
          <span style={{ marginTop: "10px" }}>
            <b>Firmware:</b> {data?.item?.firmware}
          </span>
        )}
        {isValid(data?.item?.model) && (
          <span>
            <b>Modelo:</b> {data?.item?.model}
          </span>
        )}

        <span style={{ marginTop: !isValid(data?.item?.model) ? "5px" : "" }}>
          <b>Id :</b> {data?.item?.id}
        </span>
        <span>
          <b>Serial :</b> {data?.item?.serial}
        </span>
        <span>
          <b>TypeGateway :</b> {data?.item?.typeGateway}
        </span>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          <TextField
            helperText={
              errors.name && t("COMPONENT_EDIT_CONTROLLER.ALERT_TEXT")
            }
            size="small"
            sx={{ marginTop: "5px", width: "85%" }}
            placeholder="Nombre"
            defaultValue={data?.item?.name}
            disabled={!editName}
            label={t("COMPONENT_EDIT_CONTROLLER.CONTROLLER_NAME")}
            onChange={handlerEditTextName}
          />
          <EditOutlinedIcon
            sx={{
              placeContent: "center",
              cursor: "pointer",
              color: "#909090",
              "&:hover": { color: "#626262" },
            }}
            onClick={handlerEditName}
          />
        </div>

        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <TextField
            helperText={
              errors.projectId &&
              t("COMPONENT_EDIT_CONTROLLER.ALERT_ID_PROJECT")
            }
            size="small"
            disabled={!editProject}
            sx={{ marginTop: "5px", width: "85%" }}
            placeholder="ProjectId"
            label={t("COMPONENT_EDIT_CONTROLLER.CHANGE_CONTROLLER")}
            id="projectId"
            select={<multiple />}
            defaultValue={projectIdAssociated}
            onChange={handlerEditIdProject}
            slotProps={{
              select: {
                native: true,
              },
            }}
          >
            {projects?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <EditOutlinedIcon
            sx={{
              placeContent: "center",
              cursor: "pointer",
              color: "#909090",
              "&:hover": { color: "#626262" },
            }}
            onClick={handlerEditProject}
          />
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          placeContent: "center",
          marginTop: "-14px",
        }}
      >
        <Button
          sx={{
            borderRadius: "8px",
            marginBottom: "5px",
            backgroundColor: "#2EB142",
            color: "white",
            fontFamily: "Montserrat, sans serif ",
            "&:hover": {
              backgroundColor: "#336535",
            },
          }}
          onClick={updated}
        >
          {t("COMPONENT_EDIT_CONTROLLER.BUTTON")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
