import React, { useState, useEffect, useContext } from "react";
import { Switch, Redirect, useHistory } from "react-router-dom";

//component
import SideBar from "../../../components/sidebar/SideBar";
import Router_per_Components from "../../../config/routing/router_per_component";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import { AuthContext } from "../../../context/AuthProvider";
import MuiAlert from "@material-ui/lab/Alert";
import AccountMenu from "../../../components/menu/avatarMenu/AvatarMenu";

//components
import ConfirmationDialog from "../../../components/modal/ConfirmationModal";

//multilenguage
import LanguageSelect from "../../../components/languageSelect/LanguageSelect";

//helpers functions 
import {ClearLocalStorageExcept} from "../../../utils/helpers";
import { ModalChangePasswordRequired } from "../../../components/modal/modalChangePassword/ModalChangePasswordRequired";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
const Principal = () => {
  const [alert, setAlert] = useState(false);
  const [reload, setReload] = useState(false);
  const classes = useStyles();
  const [openSidebar, setOpenSidebar] = useState(false);
  var timeoutID;
  const { permissionsRoleUser } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    localTime();
  }, []);

  //indentificate all actions
  function setup() {
    document.addEventListener("mousemove", resetTimer, false);
    document.addEventListener("mousedown", resetTimer, false);
    document.addEventListener("keypress", resetTimer, false);
    document.addEventListener("DOMMouseScroll", resetTimer, false);
    document.addEventListener("mousewheel", resetTimer, false);
    document.addEventListener("touchmove", resetTimer, false);
    document.addEventListener("MSPointerMove", resetTimer, false);
    startTimer();
  }

  setup();

  function startTimer() {
    // wait 2 seconds before calling goInactive
    timeoutID = window.setTimeout(goInactive, 30 * 60 * 1000);
  }

  function resetTimer(e) {
    window.clearTimeout(timeoutID);

    goActive();
  }

  function goInactive() {
    // do something
    window.location.reload();
    ClearLocalStorageExcept("language");
    history.push("/");
  }

  function goActive() {
    // do something

    startTimer();
  }

  const pathname_redirect = () => {
    history.goBack();
  };

  const handleOpen = () => {
    setOpenSidebar(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert(false);
  };

  const localTime = () => {
    let date = new Date();
    localStorage.setItem("localTime", date.toLocaleDateString());
  };

  return (
    <div className="principal_container">
      <div className="principal_container_sidebar">
        <SideBar {...{ handleOpen }} />
      </div>
      <div className="principal_container_content">
        <Snackbar open={alert} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="warning">
            Su sesión será cerrada por inactividad{" "}
          </Alert>
        </Snackbar>
        <div className="navbar_container">
          <span className="navbar_title">
            <span className="navbar_menu" onClick={() => setOpenSidebar(true)}>
              <i className="fas fa-bars"></i>
            </span>
            {openSidebar ? (
              <div className="navbar_close_container">
                <span
                  className="navbar_close"
                  onClick={() => setOpenSidebar(false)}
                >
                  <i className="fas fa-times __icon_close__"></i>
                </span>
                <SideBar {...{ handleOpen }} />
              </div>
            ) : (
              <></>
            )}
            {window.location.pathname !== "/app/projects" &&
            window.location.pathname !==
              "/app/stepperviewmacrometer/description" &&
            window.location.pathname !==
              "/app/stepperviewmacrometer/description/macrometervariables" &&
            window.location.pathname !==
              "/app/stepperviewmacrometer/description/macrometervariables/macrometerdatavalidation" &&
            window.location.pathname !==
              "/app/stepperviewmacrometer/description/macrometervariables/macrometerdatavalidation/finalization" ? (
              <button
                className="button_back"
                onClick={() =>{ pathname_redirect()  
                   sessionStorage.removeItem("resultadoFinal")}}
              >
                <i className="zmdi zmdi-long-arrow-left"></i>
              </button>
            ) : (
              <></>
            )}
          </span>

          <div className="navbar_btn_logout">
            <LanguageSelect
              color={"white"}
              fontSize={"18px"}
              iconHeight={"24px"}
              iconWidth={"24px"}
              borderRadious={"20px"}
              border={"none"}
            />
            <AccountMenu />
          </div>
        </div>
        <div
          className={`${
            openSidebar ? "active_menu" : ""
          } principal_container_views`}
        >
          <Switch>{Router_per_Components()}</Switch>
        </div>
      </div>

      <ConfirmationDialog />
     <ModalChangePasswordRequired /> 
    </div>
  );
};

export default Principal;
