import gql from "graphql-tag";

export const GET_ALL_ROOMS_BY_PROJECT = gql`
  query ($ProjectId: Int!, $id: Int) {
    getMettersInProjets(ProjectId: $ProjectId)
    getInfoMetersByProject(ProjectId: $ProjectId)
    getProjectByIdHeader(ProjectId: $ProjectId)
    getAllProjects(id: $id)
    getAllRoomsByProject(project_id: $ProjectId) {
      id
      name
      floor
      status
      alias
      NewRoomId
      visible
      Device {
        variables
        visible
        name
        id
        alias
        Category {
          id
          name
          category
          sub_category
        }
      }
      Controller {
        id
        serial
        online
        typeGateway
      }
    }
  }
`;

export const GET_NEW_ALL_ROOMS_BY_PROJECT = gql`
  query ($ProjectId: Int!, $id: Int) {
    getAllProjects(id: $id)
    getProjectByIdHeader(ProjectId: $ProjectId)
    getAllNewRoomsByProject(ProjectId: $ProjectId) {
      New_Controller {
        id
        serial
        typeGateway
      }
      name
      floor
      id
      status
      alias
      visible
      New_Devices {
        id
        name
        alias
        reachable
        visible
        New_Items {
          id
          item_id
          value
          enum
          show
        }
        New_Category {
          category
          sub_category
        }
      }
    }
  }
`;

export const GET_ALL_CONTROLLERS = gql`
  query Query($projectId: Int) {
    getAllControllers(ProjectId: $projectId)
  }
`;

export const VIRTUAL_CONTROLLER_CREATE_ROOM = gql`
  mutation Mutation($data: [NewRoomsInput]) {
    CreateNewRooms(data: $data)
  }
`;

export const UPDATE_CONTROLLER = gql`
  mutation UpdateController(
    $updateControllerId: Int!
    $typeGateway: String!
    $data: RequestController
  ) {
    UpdateController(
      id: $updateControllerId
      typeGateway: $typeGateway
      data: $data
    )
  }
`;

export const UPDATE_ROOM = gql`
  mutation UpdateRoom(
    $updateRoomId: Int!
    $typeGateway: String!
    $data: RoomRequest
  ) {
    UpdateRoom(id: $updateRoomId, typeGateway: $typeGateway, data: $data) {
      id
      name
      status
      alias
    }
  }
`;

export const UPDATE_DEVICES = gql`
  mutation UpdateDevice(
    $updateDeviceId: Int!
    $typeGateway: String!
    $name: String
    $alias: String
    $visible: Boolean
    $reportEnergy: Boolean
    $reportOccupation: Boolean
    $watts: String
    $setpointTemp: String
  ) {
    UpdateDevice(
      id: $updateDeviceId
      typeGateway: $typeGateway
      name: $name
      alias: $alias
      visible: $visible
      report_energy: $reportEnergy
      report_occupation: $reportOccupation
      watts: $watts
      setpoint_temp: $setpointTemp
    ) {
      alias
      id
      name
      report_energy
      physic_id
      report_occupation
      report_temp
      setpoint_temp
      version
      visible
      watts
      watts_min
      watts_off
    }
  }
`;
