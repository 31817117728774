//React Hooks
import {
  useEffect,
  useState,
  useContext,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";

//Global Context
import { AuthContext } from "../../../context/AuthProvider";

//GraphQl hooks and queries
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_DATA_REPORT,
  GET_NEW_DATA_OCCUPANCY,
} from "../../../controllers/reportController";
import { PDF_OCUPATION_BY_ROOM } from "../../../controllers/pdfControllers";

//Canvas Library
import html2canvas from "html2canvas";

//Components
import AreaSelector from "../../areaSelector/AreaSelector";
import DatePicker from "../../globals/datepicker/datepicker";
import { DateRangeContext } from "../../reactDateRange/context/ReactDateRangeContext";
import Loading from "../../loading/Loading";
import OcupationTable from "./OcupationTable";
import OccupancyChart from "../../highcharts/OccupancyChart";
import { ReactDateRangePicker } from "../../../components/reactDateRange/index";

//Helper Functions
import { formatDateLocal } from "../../../utils/helpers";
import { handleEventDate } from "./helper";

const Ocupation = forwardRef((props, ref) => {
  const { project, RoomId, typeGateway } = props;

  const {
    graphic,
    setGraphic,
    setLoadingPdf,
    dateCalendar,
    setDateCalendar,
    setIsOpenCalendarCustom,
    dateCalendarTemp,
    calendarDatePicker, // calendar
    setCalendarDatePicker, // setCalendar
    setIdHeaderOcupation,
    idHeaderOcupation,
    calendarDatePickerToday,
    setCalendarDatePickerToday,
    globalReactDateRange,
    globalCalendarDay,
  } = useContext(AuthContext);

  const [TypeGateway, setTypeGateway] = useState(typeGateway);
  const [pdfGenerator] = useMutation(PDF_OCUPATION_BY_ROOM);
  const printRef = useRef();
  const [basePdf, setBasePdf] = useState();
  const [parametroMin, setParametroMin] = useState();

  const init_time = (time) => {
    const time_now = new Date().getHours();
    if (time_now === 15 && time === "15:00") {
      const time_actual = new Date().getTime() - 86400000;
      return new Date(time_actual);
    }
    return new Date();
  };

  const [parameterMax, setParameterMax] = useState(new Date());
  const [parameterMin, setParameterMin] = useState(
    init_time(project?.start_time)
  );
  const [stateRoomId, setStateRoomId] = useState(RoomId);
  const [newRoomId, setNewRoomId] = useState(props?.NewRoomId);

  const [getDataReport, { data: dataOcupation150, loading: loading150 }] =
    useLazyQuery(GET_DATA_REPORT);

  const [getNewDataOccupancy, { data: dataOcupation700, loading: loading700 }] =
    useLazyQuery(GET_NEW_DATA_OCCUPANCY);

  useEffect(() => {
    if (graphic) {
      dataPdf();
    }
  }, [graphic]);

  useEffect(() => {
    if (basePdf) {
      decodePdf();
    }
  }, [basePdf]);

  useEffect(() => {
    if (TypeGateway === "horus_700") {
      getNewDataOccupancy();
    } else if (TypeGateway === "horus_g150") {
      getDataReport();
    }
  }, [getNewDataOccupancy, getDataReport, newRoomId]);

  useEffect(() => {
    if (globalCalendarDay) {
      handlerEpochTimeConverter();
    }
  }, [globalCalendarDay]);

  useEffect(() => {
    handlerEpochTimeConverter();
  }, [idHeaderOcupation, newRoomId, stateRoomId]);

  const handlerEpochTimeConverter = () => {
    if (project?.start_time) {
      let epochTime = 60 * 60 * 1000;

      let startTimeZone =
        globalCalendarDay?.toString()?.substring(0, 16) +
        `${project?.start_time}:00 GMT` +
        project?.time_zone;

      let endTimeZone = new Date(startTimeZone)?.getTime() + (86400000 - 60000);

      let param =
        globalCalendarDay.toString()?.substring(0, 16) +
        `${project?.start_time}:00 GMT`;

      const parameter_min =
        new Date(param).getTime() -
        parseInt(project?.time_zone, 10) * epochTime;

      const parameter_max = new Date(endTimeZone).getTime();

      setParameterMax(parameter_max);
      setParameterMin(parameter_min);

      handleChangeTypeGateway(parameter_min, parameter_max);
    } else {
    }
  };

  useImperativeHandle(ref, () => ({
    async occupationPdf() {
      setLoadingPdf(true);
      await imageGraphic();
    },
  }));

  const handleChangeTypeGateway = (parameterMin, parameterMax) => {
    const queryHorus700 = {
      variables: {
        ProjectId: project?.id,
        NewRoomId: String(newRoomId),
        event_date: handleEventDate(parameterMin),
        start_time: Number(`${new Date(parameterMin).getTime()}`),
        end_time: Number(`${new Date(parameterMax).getTime()}`),
      },
      fetchPolicy: "no-cache",
    };

    const queryHorus150 = {
      variables: {
        project_id: project?.id,
        RoomId: parseInt(stateRoomId),
        event_date: handleEventDate(parameterMin),
        start_time: Number(`${new Date(parameterMin).getTime()}`),
        end_time: Number(`${new Date(parameterMax).getTime()}`),
      },
      fetchPolicy: "no-cache",
    };

    if (TypeGateway === "horus_700") {
      getNewDataOccupancy(queryHorus700);
    } else if (TypeGateway === "horus_g150") {
      getDataReport(queryHorus150);
    }
  };

  // With start time
  // const with_start_time = () => {
  //   let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;

  //   let day_time_zone =
  //     calendarDatePicker?.toString()?.substring(0, 16) +
  //     `${project?.start_time}:00 GMT` +
  //     project?.time_zone;
  //   let day_time_end_zone =
  //     new Date(day_time_zone)?.getTime() + (86400000 - 60000);
  //   let param =
  //     calendarDatePicker?.toString()?.substring(0, 16) +
  //     `${project?.start_time}:00 GMT`;

  //   const parameter_min =
  //     new Date(param).getTime() -
  //     parseInt(project?.time_zone, 10) * HORA_EN_MILISEGUNDO;

  //   const parameter_max = new Date(day_time_end_zone).getTime();

  //   //Parameter StartDate
  //   setParameterMin(parameter_min);
  //   //Parameter EndDate
  //   setParameterMax(parameter_max);

  //   handleChangeTypeGateway(parameter_min, parameter_max);
  // };

  // Without start time
  // const without_start_time = () => {
  //   let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
  //   let day_time_end_zone =
  //     dateCalendar?.toString()?.substring(0, 16) +
  //     "23:59:59 GMT" +
  //     project?.time_zone;
  //   let param = dateCalendar?.toString()?.substring(0, 16) + "00:00:00 GMT";

  //   let parameter_min =
  //     new Date(param).getTime() -
  //     parseInt(project?.time_zone, 10) * HORA_EN_MILISEGUNDO;

  //   const parameter_max = new Date(day_time_end_zone).getTime();

  //   //Parameter StartDate
  //   setParameterMin(parameter_min);
  //   //Parameter EndDate
  //   setParameterMax(parameter_max);
  // };

  // const handleQueryReport = () => {
  //   if (project?.start_time) {
  //     with_start_time();
  //   } else {
  //     without_start_time();
  //   }
  // };

  // const back = () => {
  //   if (project?.start_time) {
  //     const backday = calendarDatePicker; // Obtenemos el dia

  //     let day_time_zone =
  //       new Date(backday)?.toString()?.substring(0, 16) +
  //       `${project?.start_time}:00 GMT` +
  //       project?.time_zone; // Le damos el formato de e.g "Wed Jan 31 2024 00:00:00 GTM", dependiendo de la hora del start time

  //     let day_time_end_zone =
  //       new Date(day_time_zone).getTime() - (86400000 - 60000); // A la hora que calculamos anterior le sumamos 1 dias menos 1 minuto

  //     //Parámetro inicial
  //     setParameterMin(day_time_end_zone);
  //     //parámetro final
  //     setParameterMax(day_time_end_zone + (86400000 - 60000));

  //     // set el nuevo valor de calendar
  //     setCalendarDatePicker(new Date(day_time_end_zone));
  //     setCalendarDatePickerToday(new Date(day_time_end_zone));
  //     const parameter_min = Number(`${day_time_end_zone - 60000}`);

  //     const parameter_max = Number(
  //       `${day_time_end_zone + (86400000 - 120000)}`
  //     );

  //     handleChangeTypeGateway(parameter_min, parameter_max);
  //   } else {
  //     const backday = dateCalendarTemp;
  //     let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
  //     const new_backday = new Date(backday);

  //     let day_time_end_zone =
  //       new Date(new_backday)?.toString()?.substring(0, 16) +
  //       "23:59:59 GMT" +
  //       project?.time_zone;
  //     let param =
  //       new Date(new_backday)?.toString()?.substring(0, 16) + "00:00:00 GMT";

  //     let tempPara =
  //       new Date(param)?.getTime() -
  //       parseInt(project?.time_zone, 10) * HORA_EN_MILISEGUNDO;

  //     const temEndDate = new Date(day_time_end_zone)?.getTime();

  //     setDateCalendar(new_backday);

  //     //openDateCusom
  //     setIsOpenCalendarCustom(false);
  //     //Parámetro inicial
  //     setParameterMin(tempPara);
  //     //parámetro final
  //     setParameterMax(temEndDate);
  //   }
  // };

  // const next = () => {
  //   if (project?.start_time) {
  //     const nextday = calendarDatePicker; // Obtenemos el dia

  //     let day_time_zone =
  //       new Date(nextday)?.toString()?.substring(0, 16) +
  //       `${project?.start_time}:00 GMT` +
  //       project?.time_zone; // Le damos el formato de e.g "Wed Jan 31 2024 00:00:00 GTM", dependiendo de la hora del start time

  //     let day_time_end_zone = new Date(day_time_zone)?.getTime() + 86400000; // A la hora que calculamos anterior le sumamos 1 dias menos 1 minuto

  //     //Parámetro inicial
  //     setParameterMin(day_time_end_zone);
  //     //parámetro final
  //     setParameterMax(day_time_end_zone + (86400000 + 60000));

  //     // set el nuevo valor de calendar
  //     setCalendarDatePicker(new Date(day_time_end_zone));
  //     setCalendarDatePickerToday(new Date(day_time_end_zone));
  //     const parameter_min = Number(`${day_time_end_zone}`);

  //     const parameter_max = Number(`${day_time_end_zone + (86400000 - 60000)}`);

  //     handleChangeTypeGateway(parameter_min, parameter_max);
  //   } else {
  //     const nextday = dateCalendarTemp;
  //     let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
  //     const new_nextday = new Date(nextday);

  //     let day_time_end_zone =
  //       new Date(new_nextday)?.toString()?.substring(0, 16) +
  //       "23:59:59 GMT" +
  //       project?.time_zone;
  //     let param =
  //       new Date(new_nextday)?.toString()?.substring(0, 16) + "00:00:00 GMT";

  //     let tempPara =
  //       new Date(param)?.getTime() -
  //       parseInt(project?.time_zone, 10) * HORA_EN_MILISEGUNDO;

  //     const temEndDate = new Date(day_time_end_zone)?.getTime();

  //     setDateCalendar(new_nextday);
  //     //openDateCusom
  //     setIsOpenCalendarCustom(false);
  //     //Parámetro inicial
  //     setParameterMin(tempPara);
  //     //parámetro final
  //     setParameterMax(temEndDate);
  //   }
  // };

  const changeRoomId = (id) => {
    setStateRoomId(id);
    setIdHeaderOcupation(id);
    const typeGateway = JSON.parse(localStorage.getItem("areas"))
      ?.filter((i) => parseInt(i.id) === parseInt(id))
      .map((i) => i.typeGateway);

    const newRoomId = JSON.parse(localStorage.getItem("areas"))
      ?.filter((i) => parseInt(i.id) == id)
      .map((i) => i.newRoomId);
    setNewRoomId(newRoomId[0]);
    setTypeGateway(typeGateway[0]);
  };

  // useEffect(() => {
  //   setIdHeaderOcupation(props.roomIdDatepicker);
  //   if (project?.start_time) {
  //     with_start_time();
  //   } else {
  //     without_start_time();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (project?.start_time) {
  //     with_start_time();
  //   } else {
  //     without_start_time();
  //   }
  // }, [idHeaderOcupation, newRoomId, stateRoomId]);

  if (loading150) {
    return <Loading />;
  } else if (loading700) {
    return <Loading />;
  }

  const imageGraphic = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/jpg");
    setGraphic(data.slice(22));
  };

  const decodePdf = () => {
    const linkSource = `data:application/pdf;base64,${basePdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "Informe de ocupación.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setLoadingPdf(false);
    setGraphic("");
    setBasePdf("");
  };

  function dataPdf() {
    try {
      pdfGenerator({
        variables: {
          image: [graphic],
          data: handleTypeGatewayDataOccupancyPdf(),
          prefix_currency: localStorage.getItem("prefix"),
          kwh_cost: 0,
          startTime: String(parameterMin),
          finishTime: "",
          nameProject: localStorage.getItem("nameProject"),
          userName: localStorage.getItem("name"),
          imageProject: localStorage.getItem("imgProject"),
          datePrint: localStorage.getItem("localTime"),
        },
      })
        .then((resul) => {
          setBasePdf(resul.data.pdfOcupationByRoom);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }

  const handleTypeGatewayDataOccupancy = () => {
    if (TypeGateway === "horus_700") {
      return dataOcupation700?.getNewDataOccupancy;
    } else if (TypeGateway === "horus_g150") {
      return dataOcupation150?.getDataReport;
    }
  };

  const handleTypeGatewayDataOccupancyPdf = () => {
    if (TypeGateway === "horus_700") {
      const filteredReporData =
        dataOcupation700?.getNewDataOccupancy?.report_data?.filter(
          (data) =>
            data.category !== 123 &&
            data.category !== 67 &&
            data.category !== 35
        );
      return {
        report_data: filteredReporData,
        totals_consumptions_report_data:
          dataOcupation700?.getNewDataOccupancy
            ?.totals_consumptions_report_data,
      };
    } else if (TypeGateway === "horus_g150") {
      const filteredReporData =
        dataOcupation150?.getDataReport?.report_data?.filter(
          (data) =>
            data.category !== 123 &&
            data.category !== 67 &&
            data.category !== 35
        );
      return {
        report_data: filteredReporData,
        totals_consumptions_report_data:
          dataOcupation150?.getDataReport?.totals_consumptions_report_data,
      };
    }
  };

  const handleTypeGatewayDataOccupancyTable = () => {
    if (TypeGateway === "horus_700") {
      return dataOcupation700;
    } else if (TypeGateway === "horus_g150") {
      return dataOcupation150;
    }
  };

  const handleProjectTime = (time) => {
    if (time === "0:00" || time === "00:00") {
      return 0;
    } else if (time === "15:00") {
      return 15;
    }
  };

  return (
    <div className="occupation_report_container">
      {/* Calendar */}
      {/* <DatePicker
        component="occupation"
        type="calendar"
        back={back}
        next={next}
        handleQuery={handleQueryReport}
        selectOption={{
          changeInside: true,
          changeRoomId,
          RoomId,
        }}
        typeGateway={TypeGateway}
        timerProject={project?.start_time}
      /> */}
      <div className="Datepicker_AreaSelector_Container">
        <ReactDateRangePicker
          selectTypeRange="d"
          closingTime={handleProjectTime(project?.start_time)}
          closingType="n"
        />
        <AreaSelector changeRoomId={changeRoomId} />
      </div>

      {/* Grafica */}
      <div ref={printRef}>
        <OccupancyChart
          data={handleTypeGatewayDataOccupancy()}
          dateMin={parameterMin}
          dateMax={parameterMax}
          timeZone={-(project?.time_zone * 60)}
        />
      </div>

      {/* Table */}
      <OcupationTable
        reportData={handleTypeGatewayDataOccupancyTable()}
        project={project}
        typeGateway={TypeGateway}
      />
    </div>
  );
});

export default Ocupation;
