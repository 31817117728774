import { differenceInDays } from "date-fns";

//Determinar el ùltimo día seleccionable
export const lastSelectableDay = (closingTime, closingType) => {
  //Funcion para determinar la hora del día actual
  const currentDate = new Date();
  const currentTime = currentDate?.getHours();
  //Se determina la hora de inicio del proyecto
  const findIndex = closingTime;
  const projectTime = Number(findIndex);

  //Determina el último día seleccionable del DatePicker
  if (closingType?.toLocaleLowerCase() === "n") {
    if (currentTime < projectTime) {
      const currentDay = currentDate.getTime() - 86400000;
      return new Date(currentDay);
    } else if (currentTime >= projectTime) {
      const currentDay = currentDate.getTime();
      return new Date(currentDay);
    }
  } else if (closingType?.toLocaleLowerCase() === "l") {
    if (currentTime < projectTime) {
      const currentDay = currentDate.getTime() - 172800000;
      return new Date(currentDay);
    } else if (currentTime >= projectTime) {
      const currentDay = currentDate.getTime() - 86400000;
      return new Date(currentDay);
    }
  }
};

export const handlerCurrentDateDay = (currentDay, setReactDateRange) => {
  return setReactDateRange([
    {
      startDate: new Date(currentDay),
      endDate: new Date(currentDay),
      key: "selection",
    },
  ]);
};

export const handleErrorMessageDatePicker = (
  selectTypeRange,
  closingTime,
  closingType
) => {
  if (
    !selectTypeRange ||
    !["d", "w", "m", "ll"].includes(selectTypeRange?.toLocaleLowerCase())
  ) {
    throw new Error(
      "selectTypeRange is a required property for the component: <ReactDateRange/>\nalso check if the property values are correct. d (day), w (week), m (month), ll (limitless)"
    );
  } else if (!closingTime && closingTime !== 0) {
    throw new Error(
      "closingTime is a required property for the component: <ReactDateRange/>"
    );
  } else if (
    !closingType ||
    !["n", "l"].includes(closingType?.toLocaleLowerCase())
  ) {
    throw new Error(
      "closingType is a required property for the component: <ReactDateRange/> \nalso, check if the property values are correct.\nn (normal) or l (lambdas)"
    );
  }
};

export const handlerCalendarDay = (item, setCalendarDay, selectTypeRange) => {
  if (selectTypeRange === "d") {
    return setCalendarDay(item);
  }
};
export const handlerRangesCalendar = (
  item,
  setReactDateRange,
  selectTypeRange
) => {
  if (
    !selectTypeRange ||
    !["d", "w", "m", "ll"].includes(selectTypeRange?.toLocaleLowerCase())
  ) {
    throw new Error(
      "selectTypeRange is a required property for the component: <ReactDateRange/>\nalso check if the property values are correct. d (day), w (week), m (month), ll (limitless)"
    );
  } else if (selectTypeRange === "w") {
    const starDate = item?.selection?.startDate;
    const endDate = item?.selection?.endDate;
    const diffDays = differenceInDays(endDate, starDate) + 1;

    if (diffDays > 7) {
      console.log("Solo se pueden escoger 7 dias");
    } else {
      return setReactDateRange([
        {
          startDate: starDate,
          endDate: endDate,
          key: "selection",
        },
      ]);
    }
  } else if (selectTypeRange === "m") {
    const starDate = item?.selection?.startDate;
    const endDate = item?.selection?.endDate;
    const diffDays = differenceInDays(endDate, starDate) + 1;

    if (diffDays > 31) {
      console.log("Solo se pueden elegir 31 dias");
    } else {
      return setReactDateRange([
        {
          startDate: starDate,
          endDate: endDate,
          key: "selection",
        },
      ]);
    }
  } else if (selectTypeRange === "ll") {
    const starDate = item?.selection?.startDate;
    const endDate = item?.selection?.endDate;
    return setReactDateRange([
      {
        startDate: starDate,
        endDate: endDate,
        key: "selection",
      },
    ]);
  }
};

// Constants
const DAYS = {
  Mon: "Lun",
  Tue: "Mar",
  Wed: "Mie",
  Thu: "Jue",
  Fri: "Vie",
  Sat: "Sab",
  Sun: "Dom",
};

const MONTHS = {
  Jan: "Ene",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Abr",
  May: "May",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Ago",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dic",
};

// retorna el dato de la fecha actual
export const handlerFormatDate = (item) => {
  const day_week_start = item[0]?.startDate?.toString()?.substring(0, 3);
  const day_week_s = item[0]?.startDate?.toString()?.substring(8, 10);
  const month = item[0]?.startDate?.toString()?.substring(4, 7);
  const year = item[0]?.startDate?.getFullYear()?.toString();
  const day_week_end = item[0]?.endDate?.toString()?.substring(0, 3);
  const day_week_e = item[0]?.endDate?.getDate().toString();
  const key_month_end = item[0]?.endDate?.toString()?.substring(4, 7);
  const year_end = item[0]?.endDate?.getFullYear().toString();
  const end_range =
    new Date(item[0]?.startDate?.toString())?.getTime() ===
    new Date(item[0]?.endDate?.toString())?.getTime()
      ? ""
      : `${DAYS[day_week_end]} ${day_week_e}, ${MONTHS[key_month_end]} ${year_end}  `;
  return `${DAYS[day_week_start]} ${day_week_s}, ${MONTHS[month]} ${year}  ${end_range}`;
};

export const handlerFormatCalendarDay = (item) => {
  if (!item) return "";
  const day_week_start = item?.toString()?.substring(0, 3);
  const key_month_start = item?.toString()?.substring(4, 7);
  const day_start = item?.toString()?.substring(8, 10);
  const year_start = item?.toString()?.substring(11, 15);
  return `${DAYS[day_week_start]} ${day_start}, ${MONTHS[key_month_start]} ${year_start}`;
};

export const handlerPreviousDay = (globalCalendarDay, setGlobalCalendarDay) => {
  const selectedGlobalDay = new Date(globalCalendarDay).getTime();
  const epochDay = 86400000;
  const previousGlobalDay = selectedGlobalDay - epochDay;
  setGlobalCalendarDay(new Date(previousGlobalDay));
};

export const handlerNextDay = (globalCalendarDay, setGlobalCalendarDay) => {
  const selectedGlobalDay = new Date(globalCalendarDay).getTime();
  const epochDay = 86400000;
  const nextGlobalDay = selectedGlobalDay + epochDay;
  setGlobalCalendarDay(new Date(nextGlobalDay));
};

export const allowNextDayButton = (item, closingTime) => {
  const currentDay = new Date()?.toString()?.substring(0, 16);
  const currentHours = new Date().getHours();
  const selectedDay = new Date(item)?.toString()?.substring(0, 16);
  const previousDay = new Date(new Date().getTime() - 86400000)
    ?.toString()
    ?.substring(0, 16);

  if (currentHours < closingTime) {
    if (selectedDay === previousDay) {
      return false;
    } else {
      return true;
    }
  } else if (currentHours >= closingTime) {
    if (currentDay === selectedDay) {
      return false;
    } else {
      return true;
    }
  }
};

export const handlerCancelButton = (
  setDropDownCalendar,
  globalReactDateRange,
  globalCalendarDay,
  setCalendarDay,
  setReactDateRange
) => {
  setCalendarDay(globalCalendarDay);
  setReactDateRange(globalReactDateRange);
  setDropDownCalendar(false);
};
