import React, { useContext, useState } from "react";
import { last_conection } from "../../utils/helpers.js";
//multilanguage
import { useTranslation } from "react-i18next";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import { ModelEditController } from "./ModelEditController.jsx";
import { AuthContext } from "../../context/AuthProvider.js";

const Controller = (props) => {
  const [modalController, setModalController] = useState(false);
  const { t } = useTranslation();
  const { permissionsRoleUser } = useContext(AuthContext);
  const handlerOpenAndCloseModalController = () => {
    setModalController(!modalController);
  };
  return (
    <>
      <div className="__control_container">
        {permissionsRoleUser?.permissionsRole?.CtlEdit ? (
          <div
            className={`__control_container_title ${
              props?.item?.online ? "__controller_on" : "__controller_off"
            }`}
          >
            <Tooltip
              title="ver mas"
              placement="top"
              className="__controller_edit"
              onClick={handlerOpenAndCloseModalController}
            >
              <InfoOutlinedIcon
                className="__controller_edit"
                onClick={handlerOpenAndCloseModalController}
              />
            </Tooltip>
            <div className="__control_serial">{props?.item?.name}</div>
            <div className="__control_title">{`${props?.item?.serial}`}</div>
          </div>
        ) : (
          <>
            <div
              className={`__control_container_title ${
                props?.item?.online ? "__controller_on" : "__controller_off"
              }`}
            >
              <div className="__control_serial">{props?.item?.name}</div>
              <div className="__control_title">{`${props?.item?.serial}`}</div>
            </div>
          </>
        )}

        <div className="__control_container_device_areas">
          <div className="__control_items_name">
            <span>{t("VIEW_CONTROLLERS.AREAS")}</span>
          </div>
          <div className="__control_items_name">
            <span>{t("VIEW_CONTROLLERS.DEVICES")}</span>
          </div>
          <div className="__control_items_device_areas">
            <span>{props?.item?.num_rooms}</span>
          </div>
          <div className="__control_items_device_areas">
            <span>{props?.item?.num_devices}</span>
          </div>
        </div>
        <div className="__control_container_last_update">
          <div className="__control_last_update">
            <span>{`${last_conection(props?.item?.online, t)}`}</span>
          </div>
        </div>
        <ModelEditController
          open={modalController}
          data={props}
          handlerClose={handlerOpenAndCloseModalController}
          refetch={props.refetch}
        />
      </div>
    </>
  );
};

export default Controller;
