import React, { useEffect, useContext } from "react";
import { DateRange, Calendar } from "react-date-range";
import { AuthContext } from "../../../context/AuthProvider";
import {
  handlerRangesCalendar,
  handlerCancelButton,
  handlerCalendarDay,
  lastSelectableDay,
} from "../helper";

import PropTypes from "prop-types";

const Calendars = (props) => {
  const {
    selectTypeRange,
    closingTime,
    closingType,
    setDropDownCalendar,
    setAcceptButton,
    calendarDay,
    setCalendarDay,
    reactDateRange,
    setReactDateRange,
  } = props;

  const {
    globalReactDateRange,
    setGlobalReactDateRange,
    globalCalendarDay,
    setGlobalCalendarDay,
  } = useContext(AuthContext);

  //Hace un set a la variable del contexto ReactDatePicker, dependiendo de como cambie la variable global, con el fin de que funcione el boton del día anterior y siguiente
  useEffect(() => {
    if (globalCalendarDay) {
      setCalendarDay(globalCalendarDay);
    }
  }, [globalCalendarDay]);

  useEffect(() => {
    if (globalReactDateRange) {
      setReactDateRange(globalReactDateRange);
    }
  }, [globalReactDateRange]);

  return (
    <div className="DropDownButtonsAndCalendars">
      {selectTypeRange === "d" ? ( //Calendario para escoger un solo dia
        <Calendar
          onChange={(item) => {
            handlerCalendarDay(item, setCalendarDay, selectTypeRange);
          }}
          showPreview={false}
          moveRangeOnFirstSelection={false}
          showDateDisplay={false}
          date={calendarDay}
          maxDate={lastSelectableDay(closingTime, closingType)}
          rangeColors={["#4caf50e6"]}
          preventSnapRefocus={false}
          direction={"horizontal"}
        />
      ) : (
        //Calendario para escoger varios dias
        <DateRange
          onChange={(item) => {
            handlerRangesCalendar(item, setReactDateRange, selectTypeRange);
          }}
          showPreview={false}
          moveRangeOnFirstSelection={false}
          showDateDisplay={false}
          ranges={reactDateRange}
          maxDate={lastSelectableDay(closingTime, closingType)}
          months={2}
          rangeColors={["#4caf50e6"]}
          preventSnapRefocus={false}
          direction={"horizontal"}
        />
      )}
      <div className="DropDownButtonsContainer">
        <button
          className="DropDownCancelButton"
          onClick={() =>
            handlerCancelButton(
              setDropDownCalendar,
              globalReactDateRange,
              globalCalendarDay,
              setCalendarDay,
              setReactDateRange
            )
          }
        >
          Cancelar
        </button>
        <button
          className="DropDownAcceptButton"
          onClick={() => {
            setAcceptButton(true);
            setDropDownCalendar(false);
          }}
        >
          Aceptar
        </button>
      </div>
    </div>
  );
};

Calendars.propTypes = {
  selectTypeRange: PropTypes.string.isRequired,
  closingTime: PropTypes.number.isRequired,
  closingType: PropTypes.bool.isRequired,
};

export default Calendars;
