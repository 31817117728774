import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { AuthContext } from "../../context/AuthProvider";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function MessageInfo(props) {
  const { textMessage } = props;
  const { infoMessage, setInfoMessage } = useContext(AuthContext);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setInfoMessage(!infoMessage);
  };

  return (
    <Snackbar open={infoMessage} autoHideDuration={2000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="info">
        {textMessage ? textMessage : "Orden enviada"}
      </Alert>
    </Snackbar>
  );
}

export default MessageInfo;
