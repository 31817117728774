import React, { useEffect, useState, useContext, useRef } from "react";

//Global Context
import { AuthContext } from "../../context/AuthProvider";

//Swal modal component
import Swal from "sweetalert2";

//Components
import TemperatureModal from "../../views/private/devices/components/Temperature/TemperatureModal";
import Loading_devices from "../loading/Loading_devices";

//Helpers
import {
  handleBatteryLevelColor,
  handleBatteryLevel,
} from "./utils/helperBattery";

//Images
import alert from "../../assets/alert-icon.png";

const Weather = ({ item, pluggin, send, gridNumber, socketResponse }) => {
  const [BatteryLevel, setBatteryLevel] = React.useState(
    item?.variables?.BatteryLevel ? parseInt(item?.variables?.BatteryLevel) : ""
  );
  const [CurrentSetpoint, setCurrentSetpoint] = useState(
    financial(item?.variables?.CurrentSetpoint)
  );
  const [CurrentTemperature, setCurrentTemperature] = useState(
    financial(item?.variables?.CurrentTemperature)
  );
  // const [delayedSetPoint, setDelayedSetPoint] = useState(financial(item.variables.CurrentSetpoint));
  const [cool, setCool] = useState(
    item?.variables?.ModeStatus == "CoolOn" ? true : false
  );
  const [heat, setHeat] = useState(
    item?.variables?.ModeStatus == "HeatOn" ? true : false
  );
  const [auto, setAuto] = useState(
    item?.variables?.ModeStatus == "AutoChangeOver" ? true : false
  );
  const [off, setOff] = useState(
    item?.variables?.ModeStatus == "Off" ? true : false
  );

  const [previousModeThermostatState, setPreviousModeThermostatState] =
    useState({
      thermostatMode: item?.variables?.ModeStatus,
      setpointTemperature: item?.variables?.CurrentSetpoint,
    });

  const [load, setLoad] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [socketSnackbar, setSocketSnackbar] = useState(null);
  const socketInterval = useRef(null);

  // Esto hace parte del modal de confirmación
  // const [actions, setActions] = useState(0);

  const {
    infoMessage,
    setInfoMessage,
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage,
    warningMessage,
    permissionsRoleUser,
  } = useContext(AuthContext);

  const delay = 1500;

  // Esto hace parte del modal de confirmación
  // const handleClose = () => {
  //   setOpenModal(false);
  // };

  // Esto hace parte del modal de confirmación
  // const handleChanges = (a) => {
  //   // setOpenModal(true);
  //   setActions(a);
  // };

  useEffect(() => {
    setLoad(false);
  }, [errorMessage, successMessage, warningMessage]);

  useEffect(() => {
    setCurrentSetpoint(financial(item?.variables?.CurrentSetpoint));
    setCurrentTemperature(financial(item?.variables?.CurrentTemperature));
    switch (item?.variables?.ModeStatus) {
      case "CoolOn":
        setCool(true);
        setHeat(false);
        setAuto(false);
        setOff(false);
        setPreviousModeThermostatState({
          ...previousModeThermostatState,
          thermostatMode: "CoolOn",
        });
        clearInterval(socketInterval.current);
        break;
      case "HeatOn":
        setCool(false);
        setHeat(true);
        setAuto(false);
        setOff(false);
        setPreviousModeThermostatState({
          ...previousModeThermostatState,
          thermostatMode: "HeatOn",
        });
        clearInterval(socketInterval.current);
        break;
      case "AutoChangeOver":
        setCool(false);
        setHeat(false);
        setAuto(true);
        setOff(false);
        setPreviousModeThermostatState({
          ...previousModeThermostatState,
          thermostatMode: "AutoChangeOver",
        });
        clearInterval(socketInterval.current);
        break;
      case "Off":
        setCool(false);
        setHeat(false);
        setAuto(false);
        setOff(true);
        setPreviousModeThermostatState({
          ...previousModeThermostatState,
          thermostatMode: "Off",
        });
        clearInterval(socketInterval.current);
        break;
      case "CurrentSetpoint":
        setPreviousModeThermostatState({
          ...previousModeThermostatState,
          setpointTemperature: CurrentSetpoint,
        });
        clearInterval(socketInterval.current);
        break;
      default:
    }
  }, [item]);

  useEffect(() => {
    if (
      socketResponse?.status == 500 ||
      socketResponse?.status == 400 ||
      socketResponse?.status == 404 ||
      item?.variables?.CommFailure == "1" ||
      socketSnackbar === false
    ) {
      if (previousModeThermostatState?.thermostatMode === "CoolOn") {
        setCool(true);
        setHeat(false);
        setAuto(false);
        setOff(false);
      } else if (previousModeThermostatState?.thermostatMode === "HeatOn") {
        setCool(false);
        setHeat(true);
        setAuto(false);
        setOff(false);
      } else if (
        previousModeThermostatState?.thermostatMode === "AutoChangeOver"
      ) {
        setCool(false);
        setHeat(false);
        setAuto(true);
        setOff(false);
      } else if (previousModeThermostatState?.thermostatMode === "Off") {
        setCool(false);
        setHeat(false);
        setAuto(false);
        setOff(true);
      } else {
      }
      setCurrentSetpoint(
        financial(previousModeThermostatState?.setpointTemperature)
      );
      if (socketSnackbar === false) {
        setErrorMessage(true);
        setSocketSnackbar(null);
      }
    }
  }, [socketResponse, item, socketSnackbar]);

  const upSet = () => {
    const newSetPoint =
      parseInt(CurrentSetpoint) + 1 > 30 ? 30 : parseInt(CurrentSetpoint) + 1;
    setCurrentSetpoint(newSetPoint);
    // setDelayedSetPoint(newSetPoint);
    setTimeout(() => {
      setOpenModal(false);
      setLoad(true);
      send(item, String(newSetPoint), 1);
      SocketTimeValidationResponse();
    }, delay);
  };

  const downSet = () => {
    const newSetPoint =
      parseInt(CurrentSetpoint) - 1 < 16 ? 16 : parseInt(CurrentSetpoint) - 1;
    setCurrentSetpoint(newSetPoint);

    // handleClose();
    setTimeout(() => {
      setLoad(true);
      send(item, String(newSetPoint), 1);
      // setTimeout(() => {
      //   setDelayedSetPoint(newSetPoint);
      // },2500)
      SocketTimeValidationResponse();
    }, delay);
  };

  const handleChangeThermostatState = (actions) => {
    if (actions === 1) {
      upSet();
    } else if (actions === 2) {
      downSet();
    } else if (actions === 3) {
      if (!cool) {
        // handleClose();
        setTimeout(() => {
          send(item, "CoolOn", 3);
          setLoad(true);
          setCool(!cool);
          setHeat(false);
          setAuto(false);
          setOff(false);
          SocketTimeValidationResponse();
        }, 2000);
      }
    } else if (actions === 4) {
      if (!heat) {
        setOpenModal(false);
        setTimeout(() => {
          setLoad(true);
          send(item, "HeatOn", 4);
          setHeat(!heat);
          setCool(false);
          setAuto(false);
          setOff(false);
          SocketTimeValidationResponse();
        }, 2000);
      }
    } else if (actions === 5) {
      if (!auto) {
        setOpenModal(false);
        setTimeout(() => {
          setLoad(true);
          send(item, "AutoChangeOver", 5);
          setHeat(false);
          setCool(false);
          setAuto(!auto);
          setOff(false);
          SocketTimeValidationResponse();
        }, 2000);
      }
    } else if (actions === 6) {
      if (!off) {
        setOpenModal(false);
        setTimeout(() => {
          setLoad(true);
          send(item, "Off", 6);
          setHeat(false);
          setCool(false);
          setAuto(false);
          setOff(!off);
          SocketTimeValidationResponse();
        }, 2000);
      }
    }
  };

  function financial(x) {
    return Number.parseFloat(x).toFixed(0);
  }

  const SocketTimeValidationResponse = () => {
    clearInterval(socketInterval.current);
    let count = 0;
    socketInterval.current = setInterval(() => {
      count++;
      if (count > 15) {
        clearInterval(socketInterval.current);
        setSocketSnackbar(false);
        setLoad(false);
      }
    }, 1000);
  };

  return (
    <div
      className={
        pluggin != 0
          ? `_weather_container ${gridNumber}`
          : `_weather_container_pluggin ${gridNumber}_pluggin`
      }
    >
      <div
        className={`_weather_container_title_status ${
          item?.variables?.CommFailure == "1" ? "_weather_offline" : null
        }`}
      >
        <div className="_weather__title">
          <span>{item?.alias}</span>
        </div>

        <div className="battery_status">
          {BatteryLevel && (
            <span className="battery_percentage">{BatteryLevel}%</span>
          )}

          <div className="battery_icon_container">
            <span className={handleBatteryLevelColor(BatteryLevel)}>
              {BatteryLevel ? handleBatteryLevel(BatteryLevel) : null}
            </span>
          </div>
        </div>
        <div
          className={`_weather__status ${
            item?.variables?.CommFailure == "1" ? "__icon_offline__" : null
          }`}
        >
          {item?.variables?.CommFailure == "1" ? (
            <span className="__icon_alert_sensor">
              <img src={alert} alt="alert" width="25" height="25" />
            </span>
          ) : (
            <span className="__icon_wifi">
              <i className="fas fa-wifi"></i>
            </span>
          )}
        </div>
      </div>

      {/* temperature */}
      <div
        className={`_weather_container_temperature ${
          load ? "__loading_weather_other" : false
        }`}
      >
        <div
          className={`${
            off ? "__weather_temperatur_off" : ""
          } _weather_temperature`}
        >
          <span className={`${off ? "__temperatur_off" : ""} __temperature`}>
            <span
              style={{
                display: "flex",
                alignItems: "flex-start",
                fontSize: "65px",
                fontFamily: "Open24",
              }}
            >
              {CurrentSetpoint}
            </span>

            <span
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "10px",
                fontSize: "35px",
                fontFamily: "Open24",
              }}
            >
              °C
            </span>
          </span>
        </div>
        <div className="_weather_container_up_down">
          <div
            className="_weather_btn_up_down"
            onClick={
              permissionsRoleUser?.permissionsRole?.AreaDispMan
                ? () => {
                    handleChangeThermostatState(1);
                  }
                : undefined
            }
          >
            <span className="__btn_up">
              <i className="fas fa-chevron-up"></i>
            </span>
          </div>
          <div
            className="_weather_btn_up_down"
            onClick={
              permissionsRoleUser?.permissionsRole?.AreaDispMan
                ? () => {
                    handleChangeThermostatState(2);
                  }
                : undefined
            }
          >
            <span className="__btn_down">
              <i className="fas fa-chevron-down"></i>
            </span>
          </div>
        </div>
      </div>

      <div className="__loading_weather">
        {load ? <Loading_devices /> : false}
      </div>

      <div
        className={`_indoor_weather ${
          load ? "__loading_weather_other" : false
        }`}
      >
        <span className="__indoor">{CurrentTemperature}°C</span>
      </div>

      {/* status */}
      <div
        className={`_weather_container_mode ${
          load ? "__loading_weather_other" : false
        }`}
      >
        <div
          className={cool ? "_weather_mode_cool_bg" : "_weather_mode_cool"}
          onClick={
            permissionsRoleUser?.permissionsRole?.AreaDispMan
              ? () => {
                  handleChangeThermostatState(3);
                }
              : undefined
          }
        >
          <span>COOL</span>
        </div>
        <div
          className={heat ? "_weather_mode_heat_bg" : "_weather_mode_heat"}
          onClick={
            permissionsRoleUser?.permissionsRole?.AreaDispMan
              ? () => {
                  handleChangeThermostatState(4);
                }
              : undefined
          }
        >
          <span>HEAT</span>
        </div>
        <div
          className={auto ? "_weather_mode_auto_bg" : "_weather_mode_auto"}
          onClick={
            permissionsRoleUser?.permissionsRole?.AreaDispMan
              ? () => {
                  handleChangeThermostatState(5);
                }
              : undefined
          }
        >
          <span>AUTO</span>
        </div>
        <div
          className={off ? "_weather_mode_off_bg" : "_weather_mode_off"}
          onClick={
            permissionsRoleUser?.permissionsRole?.AreaDispMan
              ? () => {
                  handleChangeThermostatState(6);
                }
              : undefined
          }
        >
          <span>OFF</span>
        </div>
      </div>
      {/* se comenta modal, mentener codigo si se llega anecesitar nuevamente 
      <TemperatureModal ChangeSates={handleChangeThermostatState} open={openModal} setCancel={setCancel} setOpen={setOpenModal}/> */}
    </div>
  );
};
export default Weather;
