//React Hooks
import React, { useEffect, useState, useRef } from "react";
import { AuthContext } from "../../context/AuthProvider";

//Material UI components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";

//Material UI Functions for Components
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getHours, getMinutes } from "date-fns";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//Material UI Styles
import { makeStyles, createTheme } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";

//Data Json Files
import { partnerCountries } from "../../utils/dataCountries.json";

//query & mutation
import { useQuery, useMutation } from "@apollo/client";
import {
  CREATE_PROJECT,
  UPDATE_PROJECT,
  GET_PROJECT_BY_ID,
} from "../../controllers/projectController";

// custom components
import Loading from "../loading/Loading";

//multilanguage
import { useTranslation } from "react-i18next";

//credential management
import { encryptData, decryptData } from "../../utils/encryptDecrypt";

const useStyle = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "15px",
    padding: "15px",
  },
  forms: {
    display: "flex",
    height: "60px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      height: "120px",
      width: "235px",
    },
  },
  appbarTitle: {
    backgroundColor: "#3F9948",
    color: "#fff",
    textAlign: "center",
    justifyContent: "center",
  },
  photoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "38%",
    p: {},
  },
  textFieldsContainer: {
    display: "flex",
    height: "60%",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  gridForButtons: {
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "-webkit-fill-available",
  },
}));

const TRANSLATION_SHORTCUT =
  "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.COMPONENT_CREATE_EDIT_MODAL_PROJECT.";

export const ModalCreateOrEditProject = ({
  projectIdd,
  pressedEditButton,
  pressedCreateButton,
  filledNameProject,
  setNewFilledNameProject,
  selectedCountry,
  setNewSelectedCountry,
  selectedState,
  setNewSelectedState,
  selectedCity,
  setNewSelectedCity,
  filledStart_Time,
  setNewFilledStart_Time,
  filledEnd_Time,
  setNewFilledEnd_Time,
  setRefetchProjectTable,
  imgFilter,
  filledControlAppUsername,
  setNewFilledControlAppUsername,
  filledControlAppPassword,
  setNewFilledControlAppPassword,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyle();

  const {
    modalCreateOrEditProject,
    setModalCreateorEditProject,
    permissionsRoleUser,
  } = React.useContext(AuthContext);

  const [dataProjects, setDataProjects] = useState({
    countryCode: "",
    countryLabel: "",
    currency: "",
    enabled: false,
    projectName: "",
    projectState: "",
    projectCity: "",
    projectStart_time: "",
    projectEnd_time: "",
    projectImg: "",
    time_zone: "",
    projectControlAppUsername: "",
    projectControlAppPassword: "",
  });

  const imgRef = useRef(null);

  const [openEditAlert, setNewOpenEditAlert] = useState(false);
  const [openCreateAlert, setOpenCreateAlert] = useState(false);
  const [createProject] = useMutation(CREATE_PROJECT);
  const [editProject] = useMutation(UPDATE_PROJECT);

  const { data, loading, error, refetch } = useQuery(GET_PROJECT_BY_ID, {
    variables: { id: parseInt(projectIdd) },
  });

  const loadSelectedProject = async () => {
    const project = data?.getAllProjects?.[0];

    if (project) {
      const getTimeHours = (timeString) =>
        Number.parseInt(timeString?.substring(0, 2));
      const getTimeMinutes = (timeString) =>
        Number.parseInt(timeString?.substring(3, 5));

      const startTime = project?.start_time;
      const startTimeHour = getTimeHours(startTime);
      const startTimeMinute = getTimeMinutes(startTime);

      const endTime = project?.end_time;
      const endTimeHour = getTimeHours(endTime);
      const endTimeMinute = getTimeMinutes(endTime);

      const credentials = project?.controllersCredentials;
      const encryptedUsername = credentials?.user;
      const encryptedPassword = credentials?.password;

      const ENCRYPT_DECRYPT_KEY = process.env.REACT_APP_ENCRYPT_DECRYPT_KEY;

      let controlAppUsername = "",
        controlAppPassword = "";

      try {
        controlAppUsername = await decryptData(
          encryptedUsername,
          ENCRYPT_DECRYPT_KEY
        );
        controlAppPassword = await decryptData(
          encryptedPassword,
          ENCRYPT_DECRYPT_KEY
        );
      } catch (exceptionError) {
        console.log("Error al descifrar datos:", exceptionError);
      } finally {
        setDataProjects({
          ...dataProjects,
          projectName: project?.name,
          countryLabel: project?.country,
          projectState: project?.state,
          projectCity: project?.city,
          time_zone: project?.time_zone,
          // projectStart_time: new Date().setHours(
          //   startTimeHour,
          //   startTimeMinute,
          //   0,
          //   0
          // ),
          // projectEnd_time: new Date().setHours(
          //   endTimeHour,
          //   endTimeMinute,
          //   0,
          //   0
          // ),
          projectStart_time: project?.start_time,
          projectEnd_time: project?.end_time,
          projectImg: project?.img,
          projectControlAppUsername: controlAppUsername,
          projectControlAppPassword: controlAppPassword,
        });
      }
    } else {
      console.log("No se encontró el proyecto");
    }
  };

  useEffect(() => {
    if (data && pressedEditButton && !pressedCreateButton) {
      loadSelectedProject();
    }
  }, [data, pressedCreateButton]);

  useEffect(() => {
    if (pressedCreateButton) {
      resetInputs();
    }
  }, [pressedCreateButton]);

  const resetInputs = () => {
    setDataProjects({
      ...dataProjects,
      projectName: "",
      countryCode: "",
      countryLabel: "",
      currency: "",
      prefix_currency: "",
      projectState: "",
      projectCity: "",
      // projectStart_time: new Date().setHours(0, 0, 0, 0),
      // projectEnd_time: new Date().setHours(23, 59, 0, 0),
      projectStart_time: "0:00",
      projectEnd_time: "23:59",
      time_zone: "",
      projectImg: "",
      projectControlAppUsername: "",
      projectControlAppPassword: "",
      enabled: false,
    });
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-ZñÑáÁéÉíÍóÓúÚ.0-9\s]*$/;
    if (!regex.test(value)) {
      setNewFilledNameProject(false);
      return;
    }
    setDataProjects({
      ...dataProjects,
      projectName: value,
    });
    setNewFilledNameProject(value.length >= 3);
  };

  const handleSelectCountry = (name) => {
    const selectedCode = partnerCountries?.find((i) => i?.name === name);
    if (name === "" || name === null) {
      setDataProjects({
        ...dataProjects,
        countryLabel: "",
        currency: "",
        prefix_currency: "",
        countryCode: "",
        projectState: "",
        projectCity: "",
      });
      setNewSelectedCountry(false);
      setNewSelectedState(false);
      setNewSelectedCity(false);
    } else {
      setDataProjects({
        ...dataProjects,
        countryCode: selectedCode?.code,
        countryLabel: selectedCode?.name,
        currency: selectedCode?.currencyInfo?.currency,
        prefix_currency: selectedCode?.currencyInfo?.currencyPrefix,
      });
      setNewSelectedCountry(true);
    }
  };

  const handleClickImg = () => {
    imgRef?.current?.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = document.createElement("img");
        image.src = e.target.result;

        image.onload = (e) => {
          const canvas = document.createElement("canvas");
          canvas.width = 400;
          canvas.height = 400;
          const context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
          const cutImg = context.canvas.toDataURL("image/jpeg", 90);
          setDataProjects({
            ...dataProjects,
            projectImg: cutImg,
          });
        };
      };
      reader.readAsDataURL(file);
    } else {
      setDataProjects({ ...dataProjects, projectImg: "" });
    }
  };

  const selectExistingImg = (evento) => {
    const img = evento.target.value;
    setDataProjects({ ...dataProjects, projectImg: img });
  };

  const handleSelectState = (event, value) => {
    if (!value || !selectedCountry || dataProjects.countryLabel === "") {
      setDataProjects({
        ...dataProjects,
        projectState: "",
        projectCity: "",
      });
      setNewSelectedState(false);
      setNewSelectedCity(false);
    } else {
      setDataProjects({
        ...dataProjects,
        projectState: value.name,
      });
      setNewSelectedState(true);
    }
  };

  const statesOptions = partnerCountries
    ?.filter((element) => element?.name === dataProjects?.countryLabel)
    ?.map((list) => list?.departmentState);

  const citiesOptions = partnerCountries
    ?.filter((element) => element?.name === dataProjects?.countryLabel)
    ?.map((list) =>
      list?.departmentState
        ?.filter((stateName) => stateName?.name === dataProjects?.projectState)
        ?.map((city) => city?.citiesDistrictMunicipalities)
    );

  const handleSelectCity = (event, value) => {
    if (!value || dataProjects?.projectState === "" || !selectedState) {
      setDataProjects({
        ...dataProjects,
        projectCity: "",
      });
      setNewSelectedCity(false);
    } else {
      setDataProjects({
        ...dataProjects,
        projectCity: value.name,
        time_zone: parseFloat(value.timezone.substring(3)),
      });
      setNewSelectedCity(true);
    }
  };

  // const handleSelect_StartTime = (convertedStart_Time) => {
  //   if (convertedStart_Time == null || convertedStart_Time == "") {
  //     setNewFilledStart_Time(false);
  //   } else {
  //     setDataProjects({
  //       ...dataProjects,
  //       projectStart_time: convertedStart_Time.$d,
  //     });
  //     setNewFilledStart_Time(true);
  //   }
  // };

  const handleSelect_StartTime_EndTime = (event) => {
    const startTime = event.target.value.substring(0, 5).trim();
    const endTime = event.target.value.substring(7, 13).trim();
    if (startTime === null || startTime === "") {
      setNewFilledStart_Time(false);
    } else {
      setDataProjects({
        ...dataProjects,
        projectStart_time: startTime,
        projectEnd_time: endTime,
      });
      setNewFilledStart_Time(true);
    }
  };

  // const handleSelect_EndTime = (convertedEnd_Time) => {
  //   if (convertedEnd_Time == null || convertedEnd_Time == "") {
  //     setNewFilledEnd_Time(false);
  //   } else {
  //     setDataProjects({
  //       ...dataProjects,
  //       projectEnd_time: convertedEnd_Time.$d,
  //     });
  //     setNewFilledEnd_Time(true);
  //   }
  // };

  const handleControlAppCredentials = (event, checkCredentialFilled) => {
    const { name = "", value = "" } = event.target;
    // const isValidInput = value.trim().length > 3;
    setDataProjects({ ...dataProjects, [name]: value });
    // checkCredentialFilled(isValidInput);
  };

  const handleCloseModal = () => {
    setModalCreateorEditProject(false);
    if (pressedCreateButton && !pressedEditButton) {
      resetInputs();
    }
  };

  const handleCloseAlert = () => {
    setNewOpenEditAlert(false);
    setOpenCreateAlert(false);
  };

  if (error) {
    return (
      <div>
        <lottie-player
          style={{
            width: "70%",
            marginLeft: "30%",
            marginRight: "auto",
          }}
          className="_logo"
          src="https://assets1.lottiefiles.com/private_files/lf30_tonsVH.json"
          background="transparent"
          speed="1"
          loop
          autoplay
        ></lottie-player>
      </div>
    );
  }
  if (loading) {
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    );
  }

  const refetchProjectDataTable = () => {
    setRefetchProjectTable();
  };

  const editProjects = async () => {
    if (
      filledNameProject &&
      filledStart_Time &&
      filledEnd_Time &&
      selectedCountry &&
      selectedCity &&
      selectedState
      // filledControlAppUsername &&
      // filledControlAppPassword
    ) {
      try {
        // const startHour = getHours(dataProjects?.projectStart_time);
        // const startMinute = getMinutes(dataProjects?.projectStart_time);

        // const endHour = getHours(dataProjects?.projectEnd_time);
        // const endMinute = getMinutes(dataProjects?.projectEnd_time);

        // const formattedStartTime =
        //   isNaN(startHour) || isNaN(startMinute)
        //     ? data?.getAllProjects[0]?.start_time || ""
        //     : `${startHour}:${startMinute?.toString()?.padStart(2, "0")}`;

        // const formattedEndTime =
        //   isNaN(endHour) || isNaN(endMinute)
        //     ? data?.getAllProjects[0]?.end_time || ""
        //     : `${endHour}:${endMinute?.toString()?.padStart(2, "0")}`;

        const ENCRYPT_DECRYPT_KEY = process.env.REACT_APP_ENCRYPT_DECRYPT_KEY;
        const encryptedControlAppUsername = await encryptData(
          dataProjects?.projectControlAppUsername,
          ENCRYPT_DECRYPT_KEY
        );
        const encryptedControlAppPassword = await encryptData(
          dataProjects?.projectControlAppPassword,
          ENCRYPT_DECRYPT_KEY
        );

        const projectEditionConfig = {
          variables: {
            id: projectIdd,
            name: dataProjects?.projectName,
            country: dataProjects?.countryLabel,
            state: dataProjects?.projectState,
            city: dataProjects?.projectCity,
            currency: dataProjects?.currency,
            prefix_currency: dataProjects?.prefix_currency,
            // start_time: formattedStartTime,
            // end_time: formattedEndTime,
            start_time: dataProjects?.projectStart_time,
            end_time: dataProjects?.projectEnd_time,
            time_zone: dataProjects?.time_zone,
            img: dataProjects?.projectImg,
            controllersCredentials: {
              user: encryptedControlAppUsername,
              password: encryptedControlAppPassword,
            },
          },
        };

        await editProject(projectEditionConfig).catch((rejectedReason) => {
          console.log("Error edition:", rejectedReason);
        });

        refetchProjectDataTable();

        setTimeout(() => {
          setNewOpenEditAlert(true);
          setModalCreateorEditProject(false);
        }, 500);
      } catch (exceptionError) {
        console.log(exceptionError);
      }
    }
  };

  const creatingProject = async () => {
    if (
      filledNameProject &&
      filledStart_Time &&
      filledEnd_Time &&
      selectedCountry &&
      selectedCity &&
      selectedState
      // filledControlAppUsername &&
      // filledControlAppPassword
    ) {
      try {
        const ENCRYPT_DECRYPT_KEY = process.env.REACT_APP_ENCRYPT_DECRYPT_KEY;

        // const startHour = getHours(dataProjects?.projectStart_time);
        // const startMinute = getMinutes(dataProjects?.projectStart_time);

        // const endHour = getHours(dataProjects?.projectEnd_time);
        // const endMinute = getMinutes(dataProjects?.projectEnd_time);

        // const formattedStartTime = `${startHour}:${startMinute
        //   ?.toString()
        //   ?.padStart(2, "0")}`;
        // const formattedEndTime = `${endHour}:${endMinute
        //   ?.toString()
        //   ?.padStart(2, "0")}`;

        const userId = [parseInt(localStorage.getItem("np"))];

        const encryptedControlAppUsername = await encryptData(
          dataProjects?.projectControlAppUsername,
          ENCRYPT_DECRYPT_KEY
        );
        const encryptedControlAppPassword = await encryptData(
          dataProjects?.projectControlAppPassword,
          ENCRYPT_DECRYPT_KEY
        );

        const projectCreationConfig = {
          variables: {
            name: dataProjects?.projectName,
            // start_time: formattedStartTime,
            // end_time: formattedEndTime,
            start_time: dataProjects?.projectStart_time,
            end_time: dataProjects?.projectEnd_time,
            country: dataProjects?.countryLabel,
            state: dataProjects?.projectState,
            city: dataProjects?.projectCity,
            currency: dataProjects?.currency,
            prefix_currency: dataProjects?.prefix_currency,
            time_zone: dataProjects?.time_zone,
            // img: dataProjects.projectImg,
            image: dataProjects?.projectImg,
            CityId: 1,
            UserId: userId,
            controllersCredentials: {
              user: encryptedControlAppUsername,
              password: encryptedControlAppPassword,
            },
          },
        };

        await createProject(projectCreationConfig).catch((rejectedReason) => {
          console.log("Error creation:", rejectedReason);
        });

        refetchProjectDataTable();
        setOpenCreateAlert(true);

        setTimeout(() => {
          setModalCreateorEditProject(false);
          resetInputs();
        }, 1000);
      } catch (exceptionError) {
        console.log(exceptionError);
      }
    }
  };

  if (data) {
    return (
      <>
        <Dialog
          className="user_creation_modal"
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            sx: {
              width: "500px",
              height: "auto",
              borderRadius: "8px",
              [theme.breakpoints.down("sm")]: { height: "auto" },
              [theme.breakpoints.down("md")]: { height: "auto" },
              [theme.breakpoints.down("lg")]: { height: "auto" },
            },
          }}
          open={modalCreateOrEditProject}
          onClose={() => handleCloseModal()}
        >
          <AppBar sx={{ position: "relative", backgroundColor: "#2EB142" }}>
            <Toolbar>
              <Typography
                sx={{
                  flex: "2px",
                  textAlign: "center",
                }}
                variant="h6"
                component="div"
              >
                <span>
                  {String(
                    t(
                      `${TRANSLATION_SHORTCUT}${
                        pressedEditButton ? "EDIT" : "CREATE"
                      }_TITLE`
                    )
                  )}
                </span>
              </Typography>
              <IconButton
                color="inherit"
                onClick={handleCloseModal}
                sx={{
                  display: "flex",
                  position: "absolute",
                }}
                edge="end"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <form className={classes.root} noValidate autoComplete="off">
            <div className={classes.photoContainer} onClick={handleClickImg}>
              <input
                type="file"
                ref={imgRef}
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
              <Avatar
                variant="rounded"
                src={dataProjects?.projectImg}
                sx={{
                  width: 180,
                  height: 180,
                  boxShadow: "1px 1px 4px 4px rgb(146 158 158 / 30%)",
                  cursor: "pointer",
                  ":hover": { opacity: "0.5" },

                  [theme.breakpoints.down("sm")]: {
                    width: 150,
                    height: 150,
                  },
                }}
              >
                <PhotoCamera />
              </Avatar>
            </div>

            <div className={classes.textFieldsContainer}>
              <FormControl
                size="small"
                sx={{
                  display: "flex",
                  height: 45,
                  width: 364,
                  [theme.breakpoints.down("sm")]: { width: "auto" },
                }}
              >
                <InputLabel id="demo-select-small-label">
                  {t(`${TRANSLATION_SHORTCUT}IMAGE_SELECT_HELPER_TEXT`)}
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={dataProjects?.projectImg}
                  label="Imagenes Disponibles"
                  onChange={selectExistingImg}
                >
                  {imgFilter?.map((name) => (
                    <MenuItem key={name?.name} value={name?.img}>
                      {name?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                className="name_project"
                InputProps={{ sx: { borderRadius: 2 } }}
                sx={{
                  display: "flex",
                  height: 45,
                  width: 364,
                  [theme.breakpoints.down("sm")]: { width: "auto" },
                }}
                placeholder="Nombre proyecto"
                value={dataProjects?.projectName}
                onChange={handleNameChange}
                color={filledNameProject ? "success" : "error"}
                id="nameProject"
                spacing={1}
                name="name"
                variant="outlined"
                size="small"
              />

              <Autocomplete
                id="country-select-demo"
                size="small"
                options={partnerCountries}
                value={dataProjects?.countryLabel}
                onChange={(e) => handleSelectCountry(e.target.textContent)}
                autoHighlight
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      height: 45,
                      width: 364,
                      borderRadius: 2,
                      [theme.breakpoints.down("sm")]: { width: "220px" },
                    }}
                    {...params}
                    label={t(`${TRANSLATION_SHORTCUT}COUNTRY`)}
                    color={selectedCountry ? "success" : "error"}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  >
                    {partnerCountries?.map((countryInitial) => (
                      <MenuItem
                        key={countryInitial?.code}
                        value={countryInitial?.code}
                      >
                        {countryInitial?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              <div className={classes.forms}>
                <FormControl sx={{ height: 100, width: 190 }}>
                  <Autocomplete
                    sx={{ width: "126.17px" }}
                    id="state-select-demo"
                    size="small"
                    options={statesOptions?.flat()}
                    onChange={handleSelectState}
                    disabled={
                      dataProjects?.countryLabel === "" ||
                      ![
                        "Colombia",
                        "Mexico",
                        "Puerto Rico",
                        "Ecuador",
                        "Republica Dominicana",
                      ]?.includes(dataProjects?.countryLabel)
                    }
                    value={dataProjects?.projectState}
                    autoHighlight
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{
                          borderRadius: "50px",
                          "& > img": { mr: 2, flexShrink: 0 },
                        }}
                        {...props}
                      >
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          width: "170.10px",
                          [theme.breakpoints.down("sm")]: {
                            display: "flex",

                            width: "220px",
                          },
                        }}
                        {...params}
                        label={t(`${TRANSLATION_SHORTCUT}STATE`)}
                        disabled={
                          dataProjects?.countryLabel === "" ||
                          ![
                            "Colombia",
                            "Mexico",
                            "Puerto Rico",
                            "Ecuador",
                            "Republica Dominicana",
                          ]?.includes(dataProjects?.countryLabel)
                        }
                        color={selectedState ? "success" : "error"}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      >
                        {partnerCountries?.map((state) => (
                          <MenuItem key={state?.isocode} value={state?.name}>
                            {state?.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </FormControl>
                <FormControl sx={{ height: 100, width: 190 }}>
                  <Autocomplete
                    sx={{ width: "126.17px" }}
                    id="city-select-demo"
                    size="small"
                    options={citiesOptions?.flat()?.flat()}
                    onChange={handleSelectCity}
                    value={dataProjects?.projectCity}
                    disabled={dataProjects?.projectState === ""}
                    autoHighlight
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{
                          borderRadius: "50px",
                          "& > img": { mr: 2, flexShrink: 0 },
                        }}
                        {...props}
                      >
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          width: "174.10px",
                          [theme.breakpoints.down("sm")]: {
                            display: "flex",
                            width: "220px",
                          },
                        }}
                        {...params}
                        label={t(`${TRANSLATION_SHORTCUT}CITY`)}
                        disabled={dataProjects?.projectState === ""}
                        color={selectedCity ? "success" : "error"}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      >
                        {partnerCountries?.map((city) => (
                          <MenuItem key={city?.isocode} value={city?.name}>
                            {city?.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </FormControl>
              </div>

              <div style={{ display: "flex", gap: "20px" }}>
                <FormControl
                  size="small"
                  color={filledStart_Time ? "success" : "error"}
                  sx={{
                    // display: "flex",
                    // alignItems: "center",
                    width: 365,
                    height: 50,
                    [theme.breakpoints.down("sm")]: {
                      display: "flex",
                      width: "220px",
                    },
                  }}
                >
                  <InputLabel id="demo-select-small-label">
                    {t(`${TRANSLATION_SHORTCUT}CLOSING_TIME`)}
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label={t(`${TRANSLATION_SHORTCUT}CLOSING_TIME`)}
                    value={`${dataProjects?.projectStart_time} - ${dataProjects?.projectEnd_time}`}
                    onChange={handleSelect_StartTime_EndTime}
                  >
                    <MenuItem
                      style={{ display: "flex", justifyContent: "center" }}
                      value={"0:00 - 23:59"}
                    >
                      12:00 AM - 11:59 PM
                    </MenuItem>
                    <MenuItem
                      style={{ display: "flex", justifyContent: "center" }}
                      value={"15:00 - 14:59"}
                    >
                      3:00 PM - 2:59 PM
                    </MenuItem>
                  </Select>
                </FormControl>

                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    color={filledStart_Time ? "success" : "error"}
                    InputProps={{ sx: { borderRadius: 2 } }}
                    label={t(`${TRANSLATION_SHORTCUT}START_TIME`)}
                    value={dataProjects?.projectStart_time}
                    onChange={handleSelect_StartTime}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          width: 170,
                          height: 80,
                          [theme.breakpoints.down("sm")]: {
                            display: "flex",
                            width: "220px",
                          },
                        }}
                        size={"small"}
                        name="start_time"
                        disabled={true}
                        {...params}
                      />
                    )}
                  />

                  <MobileTimePicker
                    color={filledEnd_Time ? "success" : "error"}
                    InputProps={{ sx: { borderRadius: 2 } }}
                    label={t(`${TRANSLATION_SHORTCUT}END_TIME`)}
                    value={dataProjects?.projectEnd_time}
                    onChange={handleSelect_EndTime}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          width: 175,
                          height: 80,
                          [theme.breakpoints.down("sm")]: {
                            display: "flex",
                            width: "220px",
                          },
                        }}
                        size={"small"}
                        name="start_time"
                        disabled={true}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider> */}
              </div>

              <Box
                sx={{
                  borderTop: 1,
                  borderColor: "slategrey",
                  borderTopStyle: "dashed",
                }}
                className={classes.forms}
              >
                <TextField
                  id="projectControlAppUsername"
                  name="projectControlAppUsername"
                  className="name_project"
                  size="small"
                  variant="outlined"
                  placeholder={t(`${TRANSLATION_SHORTCUT}CONTROL_APP_USERNAME`)}
                  spacing={1}
                  value={dataProjects?.projectControlAppUsername}
                  // color={filledControlAppUsername ? "success" : "error"}
                  onChange={(event) =>
                    handleControlAppCredentials(
                      event,
                      setNewFilledControlAppUsername
                    )
                  }
                  sx={{
                    display: "flex",
                    height: 45,
                    width: 168,
                    [theme.breakpoints.down("sm")]: { width: "auto" },
                  }}
                  InputProps={{ sx: { borderRadius: 2 } }}
                />
                <TextField
                  id="projectControlAppPassword"
                  name="projectControlAppPassword"
                  className="name_project"
                  type="password"
                  size="small"
                  variant="outlined"
                  placeholder={t(`${TRANSLATION_SHORTCUT}CONTROL_APP_PASSWORD`)}
                  spacing={1}
                  value={dataProjects?.projectControlAppPassword}
                  // color={filledControlAppPassword ? "success" : "error"}
                  onChange={(event) =>
                    handleControlAppCredentials(
                      event,
                      setNewFilledControlAppPassword
                    )
                  }
                  sx={{
                    display: "flex",
                    height: 45,
                    width: 174,
                    [theme.breakpoints.down("sm")]: { width: "auto" },
                  }}
                  InputProps={{ sx: { borderRadius: 2 } }}
                />
              </Box>
            </div>

            <div className={classes.buttonContainer}>
              <Button
                sx={{
                  borderRadius: 2,
                  boxShadow: "1px 1px 4px 4px rgb(146 158 158 / 30%)",
                  width: "77%",
                  [theme.breakpoints.down("sm")]: {
                    display: "flex",
                    width: "220px",
                  },
                }}
                disabled={
                  !selectedCountry ||
                  !selectedCity ||
                  !selectedState ||
                  !filledNameProject ||
                  !filledStart_Time ||
                  !filledEnd_Time
                  // !filledControlAppUsername ||
                  // !filledControlAppPassword
                }
                variant="contained"
                color="success"
                size="medium"
                onClick={pressedEditButton ? editProjects : creatingProject}
              >
                {t(`${TRANSLATION_SHORTCUT}BUTTON_TEXT`)}
              </Button>
            </div>
          </form>
        </Dialog>

        <div>
          <Snackbar
            open={openCreateAlert}
            autoHideDuration={2000}
            onClose={handleCloseAlert}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          >
            <Alert severity="success">
              {t(`${TRANSLATION_SHORTCUT}SUCCESSFUL_CREATION`)}
            </Alert>
          </Snackbar>

          <Snackbar
            open={openEditAlert}
            autoHideDuration={2000}
            onClose={handleCloseAlert}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          >
            <Alert severity="success">
              {t(`${TRANSLATION_SHORTCUT}SUCCESSFUL_EDIT`)}
            </Alert>
          </Snackbar>
        </div>
      </>
    );
  }
};
