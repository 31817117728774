import React, { createContext, useState } from "react";

export const DateRangeContext = createContext({});

export const DateRangeProvider = ({ children }) => {
  const [reactDateRange, setReactDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [calendarDay, setCalendarDay] = useState(new Date());
  const [acceptButton, setAcceptButton] = useState(false);

  return (
    <DateRangeContext.Provider
      value={{
        reactDateRange,
        setReactDateRange,
        calendarDay,
        setCalendarDay,
        acceptButton,
        setAcceptButton,
      }}
    >
      {children}
    </DateRangeContext.Provider>
  );
};
