import React, { useState, useEffect } from "react";
import { Devices } from "../../../../../constants/devices_types";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { green } from "@material-ui/core/colors";
import Loading_devices from "../../../../../components/loading/Loading_devices";
import {
  handleBatteryLevelColor,
  handleBatteryLevel,
} from "../Temperature/utils/helperBattery";
import Battery_Newlevel from "../Battery_Newlevel";

const innerTheme = createTheme({
  palette: {
    secondary: {
      main: green[500],
    },
  },
});

const StyledIconButton = styled(IconButton)({
  backgroundColor: "#EEEEEE",
  "&:hover": {
    backgroundColor: "#CCCCCC",
  },
});
export const Lock = ({
  key,
  lock,
  dataSocket,
  sendMessage,
  serial,
  controllerStatus,
}) => {
  const { alias, variables, visible, Category } = lock;
  const { category, sub_category } = Category;
  const [open, setOpen] = useState(false);
  const { items, onLine } = variables;
  const [state, setState] = useState(
    items?.find((e) => e?.name === "door_lock")?.status
  );
  const [load, setLoad] = useState(false);
  const [stateLock, setStateLock] = useState(
    state === "secured" ? true : false
  );
  const [previousState, setPreviousState] = useState("");

  const [battery, setBattery] = useState({
    itemId_Battery: "",
    percentageBattery: "",
  });
  const idLock = items?.find((i) => i?.name === "door_lock")?.item_id;
  useEffect(() => {
    if (
      dataSocket?.category === category &&
      dataSocket?.event_name === "door_lock"
    ) {
      const item = items?.find((e) => e.item_id === dataSocket?.itemId);
      if (item) {
        setState(dataSocket?.state);
        setStateLock(dataSocket?.state === "secured" ? true : false);
      }
    }

    const batteryVariable = variables?.items.find(
      (item) => item.name === "battery"
    );
    if (batteryVariable) {
      setBattery({
        itemId_Battery: batteryVariable?.item_id,
        percentageBattery: batteryVariable?.status,
      });
    }
  }, [dataSocket]);
  const handleCloseBattery = () => {
    setOpen(!open);
  };
  const handlerChanges = () => {
    setTimeout(() => {
      setStateLock(!stateLock);
    }, 500);
    setPreviousState();
    setTimeout(() => {
      // Esto hace parte del modal de confirmación
      // handleChangeClose();
      setLoad(true);
      sendMessage({
        serial,
        channel: serial,
        id: idLock,
        eventType: "door_lock",
        value: "unsecured",
      });
      setTimeout(() => {
        setLoad(false);
      }, 3000);
    }, 1000);
  };

  return (
    <div className="_generic_container">
      <div className={`_generics_container_title_status }`}>
        <div className="_generic__title">
          <span>{alias}</span>
        </div>
        <div className={`${"_generic__status__alert"}`}>
          <div
            className={`${
              alias ? "_generic__status__alert" : "__generic_sensor_wifi"
            }`}
          >
            {!alias ? (
              <span className="__icon_alert_sensor">
                <img src={alert} alt="alert" width="22" height="22" />
              </span>
            ) : (
              <div className="battery_status" onClick={handleCloseBattery}>
                {battery?.percentageBattery && (
                  <span className="battery_percentage">
                    {battery?.percentageBattery}%
                  </span>
                )}

                <div className="battery_icon_container">
                  <span
                    className={handleBatteryLevelColor(
                      battery?.percentageBattery
                    )}
                  >
                    {handleBatteryLevel(battery?.percentageBattery)}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="_generic_container_clasification">
        <div
          className={`_generic_clasification ${
            load ? "__loading_clasification" : false
          }`}
        >
          <span>
            <img
              src={
                stateLock ? Devices.DOOR_BLOQUEADO : Devices.DOOR_DESBLOQUEADO
              }
              alt="clasification"
              width="60"
              height="60"
            />
          </span>
        </div>
        <div className="__loading_devices">
          {load ? <Loading_devices /> : false}
        </div>
        <div
          className={`_generic_toggle row-decoration ${
            load ? "__loading_toogle" : false
          }`}
        >
          <div>
            <ThemeProvider theme={innerTheme}>
              <Switch
                size="medium"
                checked={stateLock}
                onClick={handlerChanges}
              />
            </ThemeProvider>
            <br></br>
            {stateLock ? (
              <span>Bloqueado</span>
            ) : (
              <span>Desbloqueado</span>
            )}{" "}
          </div>
          <div className="_generic_clasification"></div>
        </div>
      </div>
      <Battery_Newlevel
        id_Battery={battery?.itemId_Battery}
        handleCloseBattery={handleCloseBattery}
        name={alias}
        openBattery={open}
      />
    </div>
  );
};
